import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {CommonService} from "../shared/common/common-services/common-service";
import {ChargingBoxService} from "./charging-box.service";
import {OverlayService} from "../shared/common/overlay/overlay.service";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-charging-box',
  templateUrl: './charging-box.component.html',
  styleUrls: ['./charging-box.component.scss']
})
export class ChargingBoxComponent implements OnInit {

  productForm = [
    {
      key: 'productKey',
      placeholder: 'Product Key',
      value: '',
      label: 'Product Key:',
      validation: {
        required: true
      },
      errorMessage: {
        required: 'Please enter Product Key',
      },
      type: 'text',
    },
    {
      key: 'productName',
      placeholder: 'Product Name',
      value: '',
      label: 'Product Name:',
      validation: {
        required: true
      },
      errorMessage: {
        required: 'Please enter Product Name',
      },
      type: 'text',
    },
    {
      key: 'productSecret',
      placeholder: 'Product Secret',
      value: '',
      label: 'Product Secret:',
      validation: {
        required: true
      },
      errorMessage: {
        required: 'Please enter Product Name',
      },
      type: 'text',
    },
    {
      key: 'regionId',
      placeholder: 'Region Id',
      value: '',
      label: '',
      validation: {
        required: true
      },
      errorMessage: {
        required: 'Please enter Region Id',
      },
      type: 'text',
    },
  ];
  addressForm = [
    // {
    //   key: 'id',
    //   placeholder: 'Id',
    //   value: '',
    //   label: 'Id:',
    //   type: 'text'
    // },
    {
      key: 'addressLine1',
      placeholder: 'addressLine1',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'addressLine2',
      placeholder: 'addressLine1',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'city',
      placeholder: 'city',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'state',
      placeholder: 'state',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'country',
      placeholder: 'country',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'zip',
      placeholder: 'zip',
      value: '',
      label: '',
      type: 'text'
    }
  ];
  pointers = [
    {
      relationName: 'product',
      objectName: 'Product',
      formData: this.productForm,
      heading: 'Product Detail'
      // relation: true
    },
    {
      objectName: 'Apk',
      relationName: 'apk',
    },
    {
      objectName: 'ChargingBoxType',
      relationName: 'type',
    },
    {
      objectName: 'Address',
      relationName: 'address',
      formData: this.addressForm
    }
  ];
  formData = [ // deviceName, boxCode, bcode and deviceNickName
    {
      key: 'bcode',
      placeholder: 'Barcode',
      qrcode: true,
      qrcodeShow: true,
      change: (value) => {
        const ch = this.tablesData.data.find(obj => obj['bcode'] === value);
        if (ch) {
          this.chargingBoxId = ch['id'];
          this.openCrud();
        }
      },
      value: '',
      label: '', //no use
      type: 'text',
    },
    {
      key: 'boxCode',
      placeholder: 'Box Code (IMEI NUMBER)',
      value: '',
      qrcode: true,
      label: '', //no use
      type: 'text',
    },
    // {
    //   key: 'deviceNickName',
    //   placeholder: 'Device Nick Name',
    //   value: '',
    //   label: '', //no use
    //   type: 'text',
    // },
    {
      key: 'deviceName',
      placeholder: 'Name',
      value: '',
      label: '', //no use
      type: 'text',
    },
    {
      key: 'address',
      placeholder: 'Venue',
      value: '',
      label: '', //no use
      type: 'autocomplete',
      isOptionsPromise: true,
      selected: true,
      options: this.chargingBoxService.getParse('Address', []),
      // change: (prop) => {
      //   this.addressObj = prop['object'];
      // },
      viewValue: 'locationName',
      viewKey: 'id'
    },
    {
      key: 'apk',
      placeholder: 'Apk',
      value: '',
      label: '',
      type: 'select',
      disabled: true,
      isOptionsPromise: false,
      selected: true,
      options: [],
      // change: (prop) => {
      //   this.apkObj = prop['object'];
      // },
      viewValue: 'apkVersion',
      viewKey: 'id'
    },
    {
      key: 'type',
      placeholder: 'Type',
      value: '',
      label: '',
      type: 'select',
      isOptionsPromise: true,
      selected: true,
      options: this.chargingBoxService.getParse('ChargingBoxType', []),
      change: (prop) => {
        this.changeApkOptions('type', prop['object']);
      },
      viewValue: 'name',
      viewKey: 'id'
    },
    // {
    //   key: 'location',
    //   placeholder: 'Location',
    //   lat: 34.0535063,
    //   lng: -118.46487589999998,
    //   type: 'location'
    // },
  ];
  chargingBoxId;
  tablesData: any = {};
  objectName = 'ChargingBox';
  productObject: any;
  location: any;
  apkObj: any;
  chargingBoxTypeObj: any;
  addressObj: any;
  @ViewChild('crud', {static: false}) crud;

  constructor(private cm: CommonService, private chargingBoxService: ChargingBoxService,
              private vc: ViewContainerRef,
              private overlayService: OverlayService, private router: Router, private _fb: FormBuilder) {
    this.tablesData = this.cm.getChargingBoxAttributes();
    this.getProducts();
    this.cm.getParseDataById('Product', '3HVHBnFLKp').then((product) => {
      this.productObject = product;
      this.chargingBoxService.getchargingBoxSub().subscribe(event => {
        console.log(event);
      });
    });
  }

  getProducts(navigate?) {
    this.loading = true;
    this.cm.getParse(this.objectName, this.pointers, this.pageNumber, this.pageSize, this.filters).then(result => {
      this.count = result.count;
      // results contains a weight / rank in result.get('score')
      this.tablesData.data = this.chargingBoxService.getParseData(result.results, this.pointers);
      this.tablesData = {...this.tablesData};
      this.loading = false;
      const statusOnlineOfflinePromise = [];
      result.results.map((obj, index) => {
        statusOnlineOfflinePromise.push(this.cm.getParseFind('ChargingBoxStatus', 'chargingBox', obj));
      });
      Promise.all(statusOnlineOfflinePromise).then(statuses => {
        statuses.map((status, index) => {
          const object = {};
          if (status.length) {
            object['chargingBoxStatus'] = this.chargingBoxService.formatResult(status[0], 'chargingBoxStatusId'); // 0 index because find return array
            // this.tablesData.data[index] = {...this.tablesData.data[index], ...object};
            this.tablesData.data[index] = {...this.tablesData.data[index], ...{status: object['chargingBoxStatus']['status']}};
            // this.tablesData.data[index] = {...this.tablesData.data[index], ...object['chargingBoxStatus']};
          }
        });
        this.tablesData = {...this.tablesData};
        debugger
      });
      if (navigate) {
        this.router.navigate(['/chargingbox-detail'], {queryParams: {id: this.tablesData.data[0]['id']}});
      }
      this.tablesData = {...this.tablesData};
    }, error => {
      this.cm.showNotification('danger', error);
    });
  }

  pageSize = 50;
  pageNumber = 1;
  count = 0;
  loading = true;

  _pageChange(page) {
    this.pageSize = page.pageSize;
    this.pageNumber = page.pageNumber;
    this.getProducts();
  }

  async _addNew(formValues) {
    // formValues = {...formValues, ...{location: this.chargingBoxService.getGeoPoint(formValues['lat'], formValues['lng'])}};
    // const added = await this.chargingBoxService.postParseData(formValues, this.formData, this.objectName, this.productObject);
    delete formValues['apk'];
    delete formValues['type'];
    delete formValues['address'];
    this.cm.cloudFunction('addChargingBox', formValues).then(success => {
      this.overlayService.closeOverlay();
      this.cm.showNotification('success', success);
      this.getProducts(true);
    }, error => {
      this.cm.showNotification('danger', error);
    });
  }

  _update(object) {
    if (object.formValues['apk']) {
      object.formValues['apk'] = object.formValues['apk']['object'];
    } else {
      object.formValues['apk'] = null;
    }
    if (object.formValues['type']) {
      object.formValues['type'] = object.formValues['type']['object'];
    } else {
      object.formValues['type'] = null;
    }
    if (object.formValues['address']) {
      object.formValues['address'] = object.formValues['address']['object'];
    } else {
      object.formValues['address'] = null;
    }
    this.cm.getParseDataById(this.objectName, object.primaryKey).then((product) => {
      this.formData.map(formObj => {
        // if (object.formValues[formObj['key']]) { //pointer object optional
        product.set(formObj['key'], object.formValues[formObj['key']]);
        // }
      });
      product.save().then(added => {
        this.getProducts();
        this.cm.showNotification('success', added);
        this.overlayService.closeAllOverlays();
      }, (error1) => {
        this.cm.showNotification('danger', error1);
      });
    }, (error) => {
      this.cm.showNotification('danger', error);
    });
  }

  _delete(primaryKey) {
    this.cm.cloudFunction('deleteChargingBox', {chargingBoxId: primaryKey}).then(success => {
      this.getProducts();
      this.cm.showNotification('success', success);
    }, error => {
      this.cm.showNotification('danger', error);
    });
  }

  _detail(row) {
    this.router.navigate(['/chargingbox-detail'], {queryParams: {id: row['id']}});
  }

  _scanSuccess(bcode) {
    this.chargingBoxService.getParseFindChargingBoxStatus(this.objectName, 'bcode', bcode, this.pointers).then(result => {
      if (result.length) {
        this.overlayService.closeAllOverlays();
        setTimeout(() => {
          this.router.navigate(['/chargingbox-detail'], {queryParams: {id: result[0]['id']}});
        }, 1000);
      } else {
        this.cm.showNotification('danger', 'Error finding charging box with bcode ' + bcode);
      }
    })
      .catch(function (error) {
        this.cm.showNotification('danger', error);
      });
    // const ch = this.tablesData.find(obj => obj['bcode'] === bcode);
    // this.overlaySvc.closeAllOverlays();
    // setTimeout(() => {
    //   this.router.navigate(['/chargingbox-detail'], {queryParams: {id: ch['id']}});
    // }, 1000);
    // code = 'getchargefuze.com/fuzebox?box=4567'
    //   const pair = code.split('=');
    //   const bcode = pair[1];
  }


  ngOnInit() {
  }

  openCrud(data = null): void {
    this.overlayService.open({
      component: this.crud,
      data,
      viewContainerRef: this.vc,
      backdropClass: 'dark-backdrop',
      panelClass: 'dialog-panel',
      width: '42%',
      height: '20%',
    });
  }

  _confirm() {
    this.overlayService.closeAllOverlays();
    setTimeout(() => {
      this.router.navigate(['/chargingbox-detail'], {queryParams: {id: this.chargingBoxId}});
    }, 1000);
  }

  _cancel() {
    this.overlayService.closeOverlay();
  }

  changeApkOptions(fieldName, fieldValue) {
    debugger
    this.cm.getParseFind('Apk', fieldName, fieldValue).then(results => {
      this.formData[4]['options'] = this.cm.getOptionsData(results, 'id');
      this.formData[4]['disabled'] = false;
      this.formData = [...this.formData];
      // this.formData[4]['isOptionsPromise'] = true;
    });
    //   this.formData[4]['options'] = this.cm.getParseFind('Apk', fieldName, fieldValue)
    //   this.formData[4]['disabled'] = false;
    // this.formData[4]['isOptionsPromise'] = true;
  }

  filters = {};

  _applyFilter(event) {
    this.filters = {...event};
    this.getProducts();
  }

  restartalldevices() {
    this.cm.cloudFunction('restartAllDevices', {}).then(success => {
      debugger
      this.cm.showNotification('success', success);
    }, error => {
      this.cm.showNotification('danger', error);
    });
    // const ChargingBox = this.cm.ParseJs.Object.extend('ChargingBox');
    // var chargingBoxQuery = new this.cm.ParseJs.Query(ChargingBox);
    // chargingBoxQuery.exists('apk');
    // chargingBoxQuery.limit(5000)
    // chargingBoxQuery.find().then(results => {
    //   debugger
    //   const cbPromise = results.map(cb => {
    //     cb.set('apk', null);
    //     return cb.save();
    //   });
    //   Promise.all(cbPromise).then(response => {
    //     debugger
    //   });
    //
    //
    // });
  }
}

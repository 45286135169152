import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SharedModule} from '../shared/shared.module';
import {InputControlModule} from "../shared/input-control/input-control-module";
import {CommonsModule} from "../shared/common/common-module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppMaterial} from "../shared/app-material";
import {AddGlobalConfigComponent} from "./add-global-config.component";
import {AddGlobalConfigService} from "./add-global-config.service";

@NgModule({
  imports: [
    CommonModule,
    CommonsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterial,
    InputControlModule
  ],
  declarations: [
    AddGlobalConfigComponent
  ],
  providers: [
    AddGlobalConfigService
  ],
  exports: [
    AddGlobalConfigComponent
  ]
})
export class AddGlobalConfigModule {
}

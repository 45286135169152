import {Component, OnInit} from '@angular/core';
import {CommonService} from "../shared/common/common-services/common-service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {EjectBatteryService} from "../eject-battery/eject-battery.service";
import {ChargingBoxService} from "../charging-box/charging-box.service";

@Component({
  selector: 'app-status-of-chargers',
  templateUrl: './status-of-chargers.component.html',
  styleUrls: ['./status-of-chargers.component.scss']
})
export class StatusOfChargersComponent implements OnInit {
  chargingBoxForm = [ // deviceName, boxCode, bcode and deviceNickName
    {
      key: 'bcode',
      placeholder: 'Barcode',
      value: '',
      label: '', //no use
      type: 'text',
    },
    {
      key: 'boxCode',
      placeholder: 'Box Code (IMEI NUMBER)',
      value: '',
      label: '', //no use
      type: 'text',
    },
    // {
    //   key: 'deviceNickName',
    //   placeholder: 'Device Nick Name',
    //   value: '',
    //   label: '', //no use
    //   type: 'text',
    // },
    {
      key: 'deviceName',
      placeholder: 'Name',
      value: '',
      label: '', //no use
      type: 'text',
    }
    // ,
    // {
    //   key: 'location',
    //   placeholder: 'Location',
    //   lat: 34.0535063,
    //   lng: -118.46487589999998,
    //   type: 'location'
    // },
  ]
  pointers = [
    {
      objectName: 'Battery',
      relationName: 'battrys',
      relation: true
      // formData: this.addressForm
    },
    {
      objectName: 'ChargingBox',
      relationName: 'chargingBox',
      formData: this.chargingBoxForm,
      heading: 'Device Detail'
    }
  ];
  objectName = 'ChargingBoxStatus';
  ChargingBoxStatus: any = [];
  resultTotal = -1;
  indexData = 0;
  formData: any = [];
  form: FormGroup;
  tablesData: any = {};

  constructor(private _fb: FormBuilder, private cm: CommonService, private chargingBoxService: ChargingBoxService) {
    this.form = this._fb.group({});
    this.tablesData = this.cm.getStatusOfChargersAttributes();
    this.getProducts();
  }

  getProducts() {
    this.loading = true;
    this.cm.getParse(this.objectName, this.pointers, this.pageNumber, this.pageSize, this.filters).then(result => {
      this.count = result.count;
      this.resultTotal = result.results.length;
      this.tablesData.data = this.chargingBoxService.getParseData(result.results, this.pointers);
      this.tablesData = {...this.tablesData};
      this.loading = false;
      // this.cm.getPointerData().subscribe(pointer => {
      //   this.ChargingBoxStatus = pointer;
      //   this.indexData++;
      //   if (this.resultTotal === this.indexData) {
      //     this.tablesData.data = this.ChargingBoxStatus;
      //     this.tablesData = {...this.tablesData};
      //     this.loading = false;
      //   }
      // });
    }, error => {
      this.cm.showNotification('danger', error);
    });
  }

  pageSize = 50;
  pageNumber = 1;
  count = 0;
  loading = true;

  _pageChange(page) {
    this.pageSize = page.pageSize;
    this.pageNumber = page.pageNumber;
    this.getProducts();
  }

  ngOnInit() {
  }

  filters = {};

  _applyFilter(event) {
    this.filters = {...event};
    this.getProducts();
  }

}

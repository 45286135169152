import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {AddApkService} from "../add-apk/add-apk.service";
import {CommonService} from "../shared/common/common-services/common-service";
import {OverlayService} from "../shared/common/overlay/overlay.service";

@Component({
  selector: 'app-charging-box-type',
  templateUrl: './charging-box-type.component.html',
  styleUrls: ['./charging-box-type.component.scss']
})
export class ChargingBoxTypeComponent implements OnInit {
  tablesData: any = {};
  objectName = 'ChargingBoxType';
  pointers = [];
  formData = [ // deviceName, boxCode, bcode and deviceNickName
    {
      key: 'name',
      placeholder: 'Name',
      value: '',
      label: '', //no use
      type: 'text',
      validation: {
        required: true
      },
      errorMessage: 'required',
    },
    {
      key: 'description',
      placeholder: 'Description',
      value: '',
      label: '', //no use
      type: 'text',
    },
    {
      key: 'totalSlots',
      placeholder: 'Total Slots',
      value: '',
      label: '', //no use
      validation: {
        required: true
      },
      errorMessage: 'required',
  type: 'number',
    }
  ];

  constructor(private cm: CommonService, private addApkService: AddApkService, private vc: ViewContainerRef,
              private overlayService: OverlayService) {
    this.tablesData = this.cm.getChargingBoxTypeAttributes();
    this.getProducts();
  }

  getProducts() {
    this.loading = true;
    this.cm.getParse(this.objectName, this.pointers, this.pageNumber, this.pageSize, this.filters).then(result => {
      // results contains a weight / rank in result.get('score')
      this.count = result.count;
      this.tablesData.data = this.addApkService.getParseData(result.results, this.pointers);
      this.tablesData = {...this.tablesData};
      this.loading = false;
    })
      .catch((error) => {
        this.cm.showNotification('danger', error);
      });
  }

  pageSize = 50;
  pageNumber = 1;
  count = 0;
  loading = true;

  _pageChange(page) {
    this.pageSize = page.pageSize;
    this.pageNumber = page.pageNumber;
    this.getProducts();
  }

  _addNew(formValues) {
    debugger
    this.addApkService.postParseData(formValues, this.formData, this.objectName).then(success => {
      this.cm.showNotification('success', success);
      this.overlayService.closeOverlay();
      this.getProducts();
    }, (error) => {
      this.cm.showNotification('danger', error);
    });
    // this.cm.cloudFunction('addChargingBox', formValues).then(success => {
    //   this.overlayService.closeOverlay();
    //   this.cm.showNotification('success', success);
    //   this.getProducts(true);
    // }, error => {
    //   this.cm.showNotification('danger', error);
    // });
  }

  _update(object) {
    debugger
    this.cm.getParseDataById(this.objectName, object.primaryKey).then((product) => {
      this.formData.map(formObj => {
        product.set(formObj['key'], object.formValues[formObj['key']]);
      });
      debugger
      product.save().then(updated => {
        this.cm.showNotification('success', updated);
        this.overlayService.closeOverlay();
        this.getProducts();
      }, (error2) => {
        this.cm.showNotification('danger', error2);
      });
      this.getProducts();
    }, (error1) => {
      this.cm.showNotification('danger', error1);
    });
  }

  _delete(primaryKey) {
    // debugger
    this.cm.getParseDataById(this.objectName, primaryKey).then((object) => {
      object.destroy().then((success) => {
        this.cm.showNotification('success', success);
        this.getProducts();
      }, (error) => {
        // The delete failed.
        this.cm.showNotification('danger', error);
        // error is a Parse.Error with an error code and message.
      });
    });
  }

  ngOnInit() {
  }
  filters = {};
  _applyFilter(event) {
    this.filters = {...event};
    this.getProducts();
  }

}

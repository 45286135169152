import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SharedModule} from '../shared/shared.module';
import {InputControlModule} from "../shared/input-control/input-control-module";
import {CommonsModule} from "../shared/common/common-module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppMaterial} from "../shared/app-material";
import {AddNetworkConfigService} from "./add-network-config.service";
import {AddNetworkConfigComponent} from "./add-network-config.component";
import {AddNetworkConfigRoutingModule} from "./add-network-config-routing.module";

@NgModule({
  imports: [
    CommonModule,
    CommonsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterial,
    InputControlModule,
    AddNetworkConfigRoutingModule
  ],
  declarations: [
    AddNetworkConfigComponent
  ],
  providers: [
    AddNetworkConfigService
  ],
  exports: [
    AddNetworkConfigComponent
  ]
})
export class AddNetworkConfigModule {
}

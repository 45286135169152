import {Routes} from '@angular/router';
import {ListCustomerComponent} from "../../../list-customer/list-customer.component";
import {ListVenueComponent} from "../../../list-venue/list-venue.component";
import {AddVenueComponent} from "../../../add-venue/add-venue.component";
import {CustomerDetailComponent} from "../../../customer-detail/customer-detail.component";
import {StatusOfChargersComponent} from "../../../status-of-chargers/status-of-chargers.component";
import {ProductsComponent} from "../../../products/products.component";
import {EjectBatteryComponent} from "../../../eject-battery/eject-battery.component";
import {ChargingBoxComponent} from "../../../charging-box/charging-box.component";
import {AuthService} from "../../../auth/auth.service";
import {AuthGuard} from "../../../auth/auth-guard.service";
import {ChargingBoxDetailComponent} from "../../../charging-box/charging-box-detail/charging-box-detail.component";
import {RentalComponent} from "../../../rental/rental.component";
import {TestChargingBoxComponent} from "../../../test-charging-box/test-charging-box.component";
import {AddApkComponent} from "../../../add-apk/add-apk.component";
import {AddGlobalConfigComponent} from "../../../add-global-config/add-global-config.component";
import {ActivityLogComponent} from "../../../activity-log/activity-log.component";
import {ChargingBoxTypeComponent} from "../../../charging-box-type/charging-box-type.component";

export const AdminLayoutRoutes: Routes = [
  // {
  //   path: '',
  //   children: [ {
  //     path: 'dashboard',
  //     component: DashboardComponent
  // }]}, {
  // path: '',
  // children: [ {
  //   path: 'userprofile',
  //   component: UserProfileComponent
  // }]
  // }, {
  //   path: '',
  //   children: [ {
  //     path: 'icons',
  //     component: IconsComponent
  //     }]
  // }, {
  //     path: '',
  //     children: [ {
  //         path: 'notifications',
  //         component: NotificationsComponent
  //     }]
  // }, {
  //     path: '',
  //     children: [ {
  //         path: 'maps',
  //         component: MapsComponent
  //     }]
  // }, {
  //     path: '',
  //     children: [ {
  //         path: 'typography',
  //         component: TypographyComponent
  //     }]
  // }, {
  //     path: '',
  //     children: [ {
  //         path: 'upgrade',
  //         component: UpgradeComponent
  //     }]
  // }
  // {path: 'dashboard', component: DashboardComponent},
  // {path: 'user-profile', component: UserProfileComponent},
  // {path: 'table-list', component: TableListComponent},
  // {path: 'typography', component: TypographyComponent},
  // {path: 'icons', component: IconsComponent},
  // {path: 'maps', component: MapsComponent},
  // {path: 'notifications', component: NotificationsComponent},
  // {path: 'upgrade', component: UpgradeComponent},
  {
    path: 'eject-battery',
    component: EjectBatteryComponent
    , canActivate: [AuthGuard]
    // loadChildren: './../../../eject-battery/eject-battery.module#EjectBatteryModule'
  },
  {path: '', component: ListCustomerComponent, canActivate: [AuthGuard]},
  {path: 'list-customer', component: ListCustomerComponent, canActivate: [AuthGuard]},
  // {path: 'deep-link', component: ListVenueComponent, canActivate: [AuthGuard]},
  {path: 'list-venue', component: AddVenueComponent, canActivate: [AuthGuard]},
  {path: 'customer-detail', component: CustomerDetailComponent, canActivate: [AuthGuard]},
  {path: 'status-of-chargers', component: StatusOfChargersComponent, canActivate: [AuthGuard]},
  {path: 'product', component: ProductsComponent, canActivate: [AuthGuard]},
  {path: 'rental', component: RentalComponent, canActivate: [AuthGuard]},
  {path: 'apk', component: AddApkComponent, canActivate: [AuthGuard]},
  {path: 'gc', component: AddGlobalConfigComponent, canActivate: [AuthGuard]},
  {path: 'chargingbox-detail', component: ChargingBoxDetailComponent, canActivate: [AuthGuard]},
  {path: 'test-charging-box', component: TestChargingBoxComponent, canActivate: [AuthGuard]},
  {path: 'charging-box', component: ChargingBoxComponent, canActivate: [AuthGuard]},
  {path: 'type', component: ChargingBoxTypeComponent, canActivate: [AuthGuard]},
  {path: 'activity', component: ActivityLogComponent, canActivate: [AuthGuard]},
  {path: 'activity', component: ActivityLogComponent, canActivate: [AuthGuard]},
  {
    path: 'earnings',
    loadChildren: 'app/chargingbox-earnings/chargingbox-earnings.module#ChargingboxEarningsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'promo-codes',
    loadChildren: 'app/promo-codes/promo-codes.module#PromoCodesModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'network',
    loadChildren: 'app/add-network-config/add-network-config.module#AddNetworkConfigModule',
    canActivate: [AuthGuard]
  },

];

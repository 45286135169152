import {AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {CommonService} from "../shared/common/common-services/common-service";
import {OverlayService} from "../shared/common/overlay/overlay.service";
import {Router} from "@angular/router";
import {ChargingBoxService} from "../charging-box/charging-box.service";

@Component({
  selector: 'app-test-charging-box',
  templateUrl: './test-charging-box.component.html',
  styleUrls: ['./test-charging-box.component.scss']
})
export class TestChargingBoxComponent implements OnInit, AfterViewInit {
  @ViewChild('crudScan', {static: false}) crudScan;
  pointers = [
    {
      relationName: 'product',
      objectName: 'Product',
    }
  ];
  objectName = 'ChargingBox';
  tablesData: any;

  constructor(private chargingBoxService: ChargingBoxService, private cm: CommonService, private overlaySvc: OverlayService,
              private vc: ViewContainerRef, private router: Router) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.openCrud(null, this.crudScan);
  }

  _scanSuccess(code) {
    // code = 'getchargefuze.com/fuzebox?box=4567'
    const pair = code.split('=');
    const bcode = pair[1];
    this.chargingBoxService.getParseFindChargingBoxStatus(this.objectName, 'bcode', bcode, this.pointers).then(result => {
      if (result.length) {
        this.overlaySvc.closeAllOverlays();
        setTimeout(() => {
          this.router.navigate(['/chargingbox-detail'], {queryParams: {id: result[0]['id']}});
        }, 1000);
      } else {
        this.cm.showNotification('danger', 'Error finding charging box with bcode ' + bcode);
      }
    })
      .catch(function (error) {
        this.cm.showNotification('danger', error);
      });
    // const ch = this.tablesData.find(obj => obj['bcode'] === bcode);
    // this.overlaySvc.closeAllOverlays();
    // setTimeout(() => {
    //   this.router.navigate(['/chargingbox-detail'], {queryParams: {id: ch['id']}});
    // }, 1000);
    // code = 'getchargefuze.com/fuzebox?box=4567'
    //   const pair = code.split('=');
    //   const bcode = pair[1];
  }

  openCrud(data = null, component): void {
    this.overlaySvc.open({
      component: component,
      data,
      viewContainerRef: this.vc,
      backdropClass: 'dark-backdrop',
      panelClass: 'dialog-panel',
      width: '52.61%',
      height: '88%',
    });
  }

}

import {Component, Inject, OnInit, Optional} from '@angular/core';
import {CommonService} from '../shared/common/common-services/common-service';
import {environment} from '../../environments/environment';
import {Router} from "@angular/router";
import {Platform} from "@angular/cdk/platform";
import {DOCUMENT} from "@angular/common";
// import {WINDOW, DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-list-venue',
  templateUrl: './list-venue.component.html',
  styleUrls: ['./list-venue.component.scss']
})
export class ListVenueComponent implements OnInit {
  constructor(private platform: Platform, @Inject(DOCUMENT) private document: Document) {
    const insta = 'https://play.google.com/store/apps/details?id=com.instagram.android&referrer=utm_source%3Dinstagramweb%26utm_campaign%3DsignupPage%26ig_mid%3D46F537E1-1C94-4E1B-89CC-1AC9FAE7FF0D%26utm_content%3Dlo%26utm_medium%3Dbadge';
    const link = document.createElement('a');
    link.setAttribute('visibility', 'hidden');
    if (platform.IOS) {
      link.href = 'https://apps.apple.com/us/app/chargefuze/id1475987143' ;
    }
    else if (this.platform.ANDROID) {
      link.href = insta;
    } else {
      link.href = 'https://apps.apple.com/us/app/chargefuze/id1475987143' ;
    }
    link.click();
  }

  ngOnInit() {
  }
}

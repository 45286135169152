import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {environment} from "../../environments/environment";
import {CommonService} from "../shared/common/common-services/common-service";

declare const ParseUser: any;

@Injectable()
export class ListCustomerService {
  public $customerDetail = new Subject<any>();

  constructor(private cm: CommonService) {
    // this.cm.ParseJs.initialize(environment.PARSE_APP_ID);//, environment.PARSE_JS_KEY
    // this.cm.ParseJs.serverURL = environment.serverURL;
  }

  setCustomerDetail(row) {
    this.$customerDetail.next(row);
  }
  getCustomerDetail(): Observable<any> {
    return this.$customerDetail.asObservable();
  }
  getParse(objectName, pointers, pageNumber?, pageSize?, filters?) {
    // this.cm.ParseJs.User.allowCustomUserClass(true);
    // const UserTable = this.cm.ParseJs.Object.extend(this.cm.ParseJs.User);
    const query = new this.cm.ParseJs.Query(this.cm.ParseJs.User);
    // query.equalTo('language', 'en');
    query.withCount();
    query.descending('createdAt');
    return query.find();
  }

  getParseDataById(objectName, id) {
    const Product = this.cm.ParseJs.Object.extend(objectName);
    const query = new this.cm.ParseJs.Query(Product);
    return query.get(id);
  }

  getParseData(results, pointers) {
    const arr = [];
    let temp: any = {};
    results.map((result, index) => {
      temp = {...temp, ...this.formatResult(result)};
      arr.push(temp);
      // pointers.map(pointer => {
      //   if (result.attributes[pointer['relationName']]) {
      //     arr[index] = {...arr[index], ...this.formatResult(result.attributes[pointer['relationName']])};
      //   }
      // });
    });
    return arr;
  }

  formatResult(result) {
    let temp: any = {};
    temp = {...result.attributes};
    temp.id = result.id;
    return temp;
  }

  postParseData(data, parentForm, objectName, pointers?) {
    const _ObjectParent = this.cm.ParseJs.Object.extend(objectName);
    const _objectParent = new _ObjectParent();
    const keys = Object.keys(data);
    parentForm.map(formObj => {
      _objectParent.set(formObj['key'], data[formObj['key']]);
    });
    if (pointers) {
      let childs = [];
      childs = pointers.map(pointer => {
        return this._postParseData(pointer, data);
      });
      Promise.all(childs).then(childSaved => {
        childSaved.map(objchildSaved => {
          if (objchildSaved['className'] === '_User')
            _objectParent.set('user', objchildSaved);
          _objectParent.set(objchildSaved['className'], objchildSaved);
        });
        // _objectParent.set(pointer['objectName'], childSaved);
        _objectParent.save().then(prnt => {
          alert("added " + prnt.id);
        });
      });

    }
  }

  _postParseData(pointer, data) {
    if (pointer['objectName'] === 'User') {
      const user = new this.cm.ParseJs.User();
      pointer['formData'].map(formObj => {
        user.set(formObj['key'], data[formObj['key']]);
      });
      return user.signUp();
    } else {
      const _Object = this.cm.ParseJs.Object.extend(pointer['objectName']);
      const _object = new _Object();
      pointer['formData'].map(formObj => {
        _object.set(formObj['key'], data[formObj['key']]);
      });
      return _object.save();
    }
  }
}

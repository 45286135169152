import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../auth/auth-guard.service';
import {HomeComponent} from '../home/home.component';
import {AdminLayoutComponent} from "../shared/layouts/admin-layout/admin-layout.component";
import {ListVenueComponent} from "../list-venue/list-venue.component";

const routes: Routes = [
//   {
//   path: '',
//   component: HomeComponent
// },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: '../shared/layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'auth',
    loadChildren: 'app/auth/auth.module#AuthModule'
  },
  {path: 'deep-link', component: ListVenueComponent},
  // {
  //   path: 'earnings',
  //   loadChildren: 'app/chargingbox-earnings/chargingbox-earnings.module#ChargingboxEarningsModule'
  // },
  {
    path: 'admin',
    loadChildren: 'app/admin/admin.module#AdminModule'
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
  declarations: []
})

export class AppRoutingModule {
}

import {Component, OnInit} from '@angular/core';
import {CommonService} from '../shared/common/common-services/common-service';
import {environment} from '../../environments/environment';
import {EjectBatteryService} from './eject-battery.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
  selector: 'app-eject-battery',
  templateUrl: './eject-battery.component.html',
  styleUrls: ['./eject-battery.component.scss']
})
export class EjectBatteryComponent implements OnInit {
  pointers = [
    {
      objectName: 'ChargingBox',
      relationName: 'chargingBox',
      // formData: this.addressForm
    }
  ];
  objectName = 'ChargingBox'; // 'Battery';
  ChargingBoxStatus: any = [];
  formData: any = [];
  form: FormGroup;
  ismobile = false;

  constructor(private _fb: FormBuilder, private ejectBatteryService: EjectBatteryService,
              private cm: CommonService, breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.ismobile = true;
      } else {this.ismobile = false; }
    });
    this.form = this._fb.group({});
    this.getProducts();
  }

  getProducts() {
    this.ejectBatteryService.getParse(this.objectName, this.pointers).then(results => {
      const data = this.ejectBatteryService.getParseData(results, this.pointers);
      this.ChargingBoxStatus = data[0];
      Promise.all(data[1]).then(batteries => {
        debugger;
        batteries.map((battery, index) => {
          if (battery.length) {
            battery.map((btr) => {
              debugger
              this.ChargingBoxStatus[index]['battery'].push(this.ejectBatteryService.formatResult(btr, 'batteryId'));
            })
            this.ejectBatteryService.formatResult(battery, 'batteryId');
          }
        });
        this.ChargingBoxStatus
        this.buildFormData(this.ChargingBoxStatus);
        debugger
      })
      this.formatData();
    })
    // .catch(function (error) {
    //   console.log(error);
    //   // There was an error.
    // })
  }


  chargingboxids = [];
  duplicateindex = []
  buildForm = false;

  formatData() {
    this.ChargingBoxStatus;
    this.ChargingBoxStatus.map((obj, index) => {
      if (this.chargingboxids.indexOf(obj['chargingBoxId']) === -1) {
        console.log('first time');
      } else {
        const objectChr = this.ChargingBoxStatus.find(x => x['chargingBoxId'] === obj['chargingBoxId']);
        objectChr['battery'].push(obj['battery'][0]);
        this.duplicateindex.push(index);
      }
      this.chargingboxids.push(obj['chargingBoxId']);
    });
    this.duplicateindex.map((deleteIndex, index) => {
      this.ChargingBoxStatus.splice(deleteIndex - index, 1);
    })
    this.ChargingBoxStatus;
    this.buildFormData(this.ChargingBoxStatus);
    debugger
  }

  buildFormData(ChargingBoxStatus) {
    ChargingBoxStatus.map((chargingBoxStatus, index) => {
      this.formData.push({
        key: chargingBoxStatus['chargingBoxId'],
        placeholder: 'Select Battery',
        value: '',
        label: ':',
        type: 'select',
        viewValue: 'btSn',
        viewKey: 'batteryId',
        template: true,
        options: chargingBoxStatus['battery']
      });
    });
    this.buildForm = true;
  }

  ejectBattery(chargingBoxId, index) {
    const batteryId = this.form.value[chargingBoxId];
    if (batteryId) {
      this.ejectBatteryService.ejectBattery({batteryId: batteryId, chargingBoxId: chargingBoxId});
    } else {
      this.ejectBatteryService.ejectBattery({chargingBoxId: chargingBoxId});
    }
  }

  ejectBatteryAll(chargingBoxId, order) {
    this.cm.cloudFunction('ejectAllBatteries', {chargingBoxId, ejectOrder: order});
  }

  ejectCabinetsAll(chargingBoxId) {
    this.cm.cloudFunction('ejectAllCabinets', {chargingBoxId});
  }


  getButtonText(chargingBoxId, ind) {
    const batteryId = this.form.value[chargingBoxId];
    const objectChr = this.ChargingBoxStatus[ind]['battery'].find(x => x['batteryId'] === batteryId);
    return this.form.value[chargingBoxId] ? 'Eject Battery (Cabinet:' + objectChr['btBay'] + ')' : 'Eject Battery';
  }

  ngOnInit() {
  }
}

// export interface chrConfig {
//   batteries: [];
// }

import {Component, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {CommonService} from "../shared/common/common-services/common-service";
import {RentalService} from "./rental.service";

@Component({
  selector: 'app-rental',
  templateUrl: './rental.component.html',
  styleUrls: ['./rental.component.scss']
})
export class RentalComponent implements OnInit {
  objectName = 'Rental';
  rental: any = [];
  formData = [ // deviceName, boxCode, bcode and deviceNickName
    {
      key: 'bcode',
      placeholder: 'Barcode',
      value: '',
      label: '', //no use
      type: 'text',
    },
    {
      key: 'boxCode',
      placeholder: 'Box Code (IMEI NUMBER)',
      value: '',
      label: '', //no use
      type: 'text',
    },
    // {
    //   key: 'deviceNickName',
    //   placeholder: 'Device Nick Name',
    //   value: '',
    //   label: '', //no use
    //   type: 'text',
    // },
    {
      key: 'deviceName',
      placeholder: 'Name',
      value: '',
      label: '', //no use
      type: 'text',
    }
    // ,
    // {
    //   key: 'location',
    //   placeholder: 'Location',
    //   lat: 34.0535063,
    //   lng: -118.46487589999998,
    //   type: 'location'
    // },
  ];
  pointers = [
    {
      objectName: 'Battery',
      relationName: 'battery'
    },
    {
      objectName: 'chargingBox',
      relationName: 'pickedFrom',
      formData: this.formData,
      heading: 'Device Detail'
    },
    {
      objectName: 'chargingBox',
      relationName: 'returnedTo',
      formData: this.formData,
      heading: 'Device Detail'
    },
    {
      objectName: 'Address',
      relationName: 'address'
    },
    {
      objectName: 'RentalInvoice',
      relationName: 'rentalInvoice'
    },
    {
      objectName: '_User',
      relationName: 'user'
    }
  ];
  tablesData: any = {};

  constructor(private _fb: FormBuilder, private cm: CommonService, private rentalService: RentalService) {
    this.tablesData = this.cm.getRentalAttributes();
    this.getProducts();
  }

  getProducts() {
    this.loading = true;
    this.cm.getParse(this.objectName, this.pointers, this.pageNumber, this.pageSize, this.filters).then(result => {
      this.count = result.count;
      let data = [];
      const paymentInfoPromise = []
      data = this.rentalService.getParseData(result.results, this.pointers);
      data.map((obj, index) => {
        obj['RentalStartDate'] = this.formatDateTime(obj['rentedAt'], 'date');
        obj['RentalStartTime'] = this.formatDateTime(obj['rentedAt'], 'time');
        obj['RentalReturnDate'] = this.formatDateTime(obj['returnedAt'], 'date');
        obj['RentalReturnTime'] = this.formatDateTime(obj['returnedAt'], 'time');
        paymentInfoPromise.push(this.cm.getParseFind('PaymentInfo', 'user', obj['user']));
      });
      Promise.all(paymentInfoPromise).then(paymentInfos => {
        paymentInfos.map((payment, index) => {
          const object = {};
          if (payment.length) {
            debugger
            object['PaymentInfo'] = this.rentalService.formatResult(payment[0], 'PaymentInfoid'); // 0 index because find return array
            this.tablesData.data[index] = {...this.tablesData.data[index], ...object};
            this.tablesData.data[index] = {...this.tablesData.data[index], ...object['PaymentInfo']};
          }
        });
        this.tablesData = {...this.tablesData};
      });
      this.tablesData.data = data;
      this.tablesData = {...this.tablesData};
      this.loading = false;
    })
      .catch(function (error) {
        console.log(error);
        // There was an error.
      });
  }
  pageSize = 50;
  pageNumber = 1;
  count = 0;
  loading = true;
  _pageChange(page) {
    this.pageSize = page.pageSize;
    this.pageNumber = page.pageNumber;
    this.getProducts();
  }
  formatDateTime(datetime, format) {
    const d = new Date(datetime);
    if (format === 'date')
      return d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();
    return d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    // return d.getDate() + '-' + (d.getMonth() + 1) + '-' +
    //   d.getFullYear() + '  ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
  }

  ngOnInit() {
  }
  filters = {};
  _applyFilter(event) {
    this.filters = {...event};
    this.getProducts();
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SharedModule} from '../shared/shared.module';
import {InputControlModule} from "../shared/input-control/input-control-module";
import {EjectBatteryService} from "./eject-battery.service";
import {EjectBatteryComponent} from "./eject-battery.component";
import {CommonsModule} from "../shared/common/common-module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppMaterial} from "../shared/app-material";
import {EjectBatteryRoutingModule} from "./eject-battery-routing.module";


@NgModule({
  imports: [
    CommonModule,
    CommonsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterial,
    InputControlModule,
    EjectBatteryRoutingModule,
  ],
  declarations: [
    EjectBatteryComponent
  ],
  providers: [
    EjectBatteryService
  ],
  exports: [
    EjectBatteryComponent
  ]
})
export class EjectBatteryModule {
}

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {CommonService} from "../shared/common/common-services/common-service";
import {ActivityLogService} from "./activity-log.service";

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit, AfterViewInit {
  filtersData = {
    columns: [
      {
        column: 'executionTime',
        type: 'number'
      },
      {
        column: 'status',
        type: 'text'
      },
      {
        column: 'action',
        type: 'text'
      },
      {
        column: 'description',
        type: 'text'
      },
      {
        column: 'user',
        type: 'pointer'
      },
      {
        column: 'battery',
        type: 'pointer'
      },
      {
        column: 'chargingBox',
        type: 'pointer'
      },
      {
        column: 'apk',
        type: 'pointer'
      },
      {
        column: 'rental',
        type: 'pointer'
      }
    ],
    pointers: ['user', 'battery', 'chargingBox', 'apk', 'rental'],
    ObjectNames: ['User', 'Battery', 'ChargingBox', 'Apk', 'Rental'],
    user: [
      {
        column: 'username',
        type: 'text'
      },
      {
        column: 'email',
        type: 'text'
      },
      {
        column: 'emailVerified',
        type: 'toggle'
      }
    ],
    battery: [
      {
        column: 'btStat',
        type: 'number'
      },
      {
        column: 'btBay',
        type: 'number'
      },
      {
        column: 'bteq',
        type: 'number'
      },
      {
        column: 'btSn',
        type: 'text'
      }
    ],
    chargingBox: [
      {
        column: 'deviceName',
        type: 'text'
      },
      {
        column: 'bcode',
        type: 'text'
      },
      {
        column: 'boxCode',
        type: 'text'
      },
      {
        column: 'address',
        type: 'pointer'
      },
      {
        column: 'type',
        type: 'pointer'
      },
      {
        column: 'apk',
        type: 'pointer'
      }
    ],
    rental: [
      {
        column: 'status',
        type: 'text'
      },
      {
        column: 'rentedDurationMinutes',
        type: 'number'
      },
      {
        column: 'summaryStatus',
        type: 'text'
      },
      {
        column: 'pickedFrom',
        type: 'pointer'
      },
      {
        column: 'returnTo',
        type: 'pointer'
      },
      {
        column: 'user',
        type: 'pointer'
      },
      {
        column: 'battery',
        type: 'pointer'
      },
      {
        column: 'invoice',
        type: 'pointer'
      }
    ],
    apk: [
      {
        column: 'apkUrl',
        type: 'text',
      },
      {
        column: 'apkMd5',
        type: 'text',
      },
      {
        column: 'apkVersion',
        type: 'text',
      },
      {
        column: 'active',
        type: 'toggle',
      },
    ]
  }
  pointers = [
    {
      objectName: 'Rental',
      relationName: 'rental',
    },
    {
      objectName: 'User',
      relationName: 'user',
    },
    {
      objectName: 'Battery',
      relationName: 'battery',
    },
    {
      objectName: 'ChargingBox',
      relationName: 'chargingBox',
    },
    {
      objectName: 'Apk',
      relationName: 'apk',
    }
  ];
  objectName = 'ActivityLogs';
  pageSize = 50;
  pageNumber = 1;
  count = 0;
  loading = true;
  data: any = [];
  @ViewChild('card', {static: false}) card;

  _pageChange(page) {
    this.pageSize = page.pageSize;
    this.pageNumber = page.pageNumber;
    this.getProducts();
  }

  constructor(private cm: CommonService, private activityLogService: ActivityLogService) {
  }

  ngAfterViewInit() {
    this.getProducts();
  }

  getProducts() {
    this.loading = true;
    // if (this.card)
    //   this.card.scrollTop();
    // window.scroll(0,0)
    this.cm.getParse(this.objectName, this.pointers, this.pageNumber, this.pageSize, this.filters).then(result => {
      this.count = result.count;
      this.data = this.activityLogService.getParseData(result.results, this.pointers);
      this.loading = false;
    })
      .catch((error) => {
        this.cm.showNotification('danger', error);
      });
  }

  ngOnInit() {
  }

  dateFormat(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  filters = {};
  _applyFilter(event) {
    this.filters = {...event};
    this.getProducts();
  }
}

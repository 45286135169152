import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {CommonService} from "../shared/common/common-services/common-service";
@Injectable()
export class ActivityLogService {

  constructor(private http: HttpClient, private cm: CommonService) {
  }
  getParse(objectName, pointers, pageNumber?, pageSize?) {
    const Product = this.cm.ParseJs.Object.extend(objectName);
    const query = new this.cm.ParseJs.Query(Product);
    if (pageNumber && pageSize) {
      const limit = pageSize * pageNumber;
      const skip = pageSize * (pageNumber - 1);
      if (pageNumber > 1)
        query.skip(skip);
      query.limit(limit);
    }
    query.withCount();
    query.descending('createdAt');
    // query.equalTo(fieldName, fieldValue);
    // query.startsWith("name", "Big Daddy's");
    // query.fullText('name', 'bbq');
    pointers.map(pointer => {
      query.include(pointer['relationName']);
    });
    return query.find();
  }
  getParseData(results, pointers) {
    const arr = [];
    let temp: any = {};
    results.map((result, index) => {
      temp = {...temp, ...this.formatResult(result, 'id')};
      arr.push(temp);
      pointers.map(pointer => {
        if (result.attributes[pointer['relationName']]) {
          const object = {};
          object[pointer['relationName']] = this.formatResult(result.attributes[pointer['relationName']]);
          arr[index] = {...arr[index], ...object};
          arr[index] = {...arr[index], ...object[pointer['relationName']]};
        }
      });
    });
    return arr;
  }

  formatResult(result, id?) {
    let temp: any = {};
    temp = {...result.attributes};
    if (id)
      temp[id] = result.id;
    return temp;
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SharedModule} from '../shared/shared.module';
import {InputControlModule} from "../shared/input-control/input-control-module";
import {CommonsModule} from "../shared/common/common-module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppMaterial} from "../shared/app-material";
import {ChargingBoxService} from "./charging-box.service";
import {ChargingBoxComponent} from "./charging-box.component";
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import {ChargingBoxDetailComponent} from "./charging-box-detail/charging-box-detail.component";
import {EjectBatteryService} from "../eject-battery/eject-battery.service";
import {TestChargingBoxComponent} from "../test-charging-box/test-charging-box.component";

@NgModule({
  imports: [
    CommonModule,
    CommonsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterial,
    InputControlModule,
    ZXingScannerModule
  ],
  declarations: [
    ChargingBoxComponent, ChargingBoxDetailComponent, TestChargingBoxComponent
  ],
  providers: [
    ChargingBoxService, EjectBatteryService
  ],
  exports: [
    ChargingBoxComponent, ChargingBoxDetailComponent, TestChargingBoxComponent
  ]
})
export class ChargingBoxModule {
}

import {Component, OnInit} from '@angular/core';
import {CommonService} from "../shared/common/common-services/common-service";

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  formData = [
    {
      key: 'productKey',
      placeholder: 'Product Key',
      value: '',
      label: 'Product Key:',
      validation: {
        required: true
      },
      errorMessage: {
        required: 'Please enter Product Key',
      },
      type: 'text',
    },
    {
      key: 'productName',
      placeholder: 'Product Name',
      value: '',
      label: 'Product Name:',
      validation: {
        required: true
      },
      errorMessage: {
        required: 'Please enter Product Name',
      },
      type: 'text',
    },
    {
      key: 'productSecret',
      placeholder: 'Product Secret',
      value: '',
      label: 'Product Secret:',
      validation: {
        required: true
      },
      errorMessage: {
        required: 'Please enter Product Name',
      },
      type: 'text',
    },
  ];
  tablesData: any = {};
  objectName = 'Product';
  constructor(private cm: CommonService) {
    this.tablesData = this.cm.getProduct();
  }

  ngOnInit() {
  }
}

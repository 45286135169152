import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AdminLayoutRoutes} from './admin-layout.routing';
import {
  MatButtonModule,
  MatInputModule,
  MatRippleModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatSelectModule
} from '@angular/material';
import {CommonsModule} from '../../common/common-module';
import {InputControlModule} from '../../input-control/input-control-module';
import {CustomerDetailComponent} from "../../../customer-detail/customer-detail.component";
import {StatusOfChargersComponent} from "../../../status-of-chargers/status-of-chargers.component";
import {ProductsComponent} from "../../../products/products.component";
import {AppMaterial} from "../../app-material";
import {EjectBatteryModule} from "../../../eject-battery/eject-battery.module";
import {ListCustomerModule} from "../../../list-customer/list-customer.module";
import {ChargingBoxModule} from "../../../charging-box/charging-box.module";
import {SharedModule} from "../../shared.module";
import {AuthModule} from "../../../auth/auth.module";
import {AddVenueModule} from "../../../add-venue/add-venue.module";
import {RentalModule} from "../../../rental/rental.module";
import {AddApkModule} from "../../../add-apk/add-apk.module";
import {AddGlobalConfigModule} from "../../../add-global-config/add-global-config.module";
import {ActivityLogModule} from "../../../activity-log/activity-log.module";
import {ChargingBoxTypeModule} from "../../../charging-box-type/charging-box-type.module";
import {FiltersModule} from "../../../filters/filters.module";
import {AddNetworkConfigModule} from "../../../add-network-config/add-network-config.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AdminLayoutRoutes),
    CommonsModule,
    SharedModule,
    AuthModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterial,
    InputControlModule,
    EjectBatteryModule,
    ChargingBoxModule,
    ListCustomerModule,
    ListCustomerModule,
    ActivityLogModule,
    RentalModule,
    // FiltersModule,
    AddApkModule,
    AddNetworkConfigModule,
    AddGlobalConfigModule,
    ChargingBoxTypeModule,
    AddVenueModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
  ],
  declarations: [
    CustomerDetailComponent,
    StatusOfChargersComponent,
    ProductsComponent]
})

export class AdminLayoutModule {
}

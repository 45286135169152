import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {AddApkService} from "./add-apk.service";
import {CommonService} from "../shared/common/common-services/common-service";
import {OverlayService} from "../shared/common/overlay/overlay.service";

@Component({
  selector: 'app-add-apk',
  templateUrl: './add-apk.component.html',
  styleUrls: ['./add-apk.component.scss']
})
export class AddApkComponent implements OnInit {
  tablesData: any = {};
  objectName = 'Apk';
  pointers = [
    {
      objectName: 'ChargingBoxType',
      relationName: 'type',
    }
  ];
  chargingBoxTypeObj: any;
  formData = [ // deviceName, boxCode, bcode and deviceNickName
    {
      key: 'apkUrl',
      placeholder: 'Apk Url',
      value: '',
      label: '', //no use
      type: 'text',
    },
    {
      key: 'apkMd5',
      placeholder: 'Apk Md5',
      value: '',
      label: '', //no use
      type: 'text',
    },
    {
      key: 'apkVersion',
      placeholder: 'Apk Version',
      value: '',
      label: '', //no use
      type: 'text',
    },
    {
      key: 'active',
      placeholder: 'Active',
      value: false,
      label: '',
      type: 'toggle',
    },
    {
      key: 'apkFile',
      type: 'file',
    },
    {
      key: 'type',
      placeholder: 'Type',
      value: '',
      label: '',
      type: 'select',
      isOptionsPromise: true,
      selected: true,
      options: this.addApkService.getParse('ChargingBoxType', []),
      // change: (prop) => {
      //   this.chargingBoxTypeObj = prop['object'];
      // },
      viewValue: 'name',
      viewKey: 'id'
    }
  ];

  constructor(private cm: CommonService, private addApkService: AddApkService, private vc: ViewContainerRef,
              private overlayService: OverlayService) {
    this.tablesData = this.cm.getApkAttributes();
    this.getProducts();
  }

  getProducts() {
    this.loading = true;
    this.cm.getParse(this.objectName, this.pointers, this.pageNumber, this.pageSize, this.filters).then(result => {
      // results contains a weight / rank in result.get('score')
      this.count = result.count;
      this.tablesData.data = this.addApkService.getParseData(result.results, this.pointers);
      debugger
      this.tablesData = {...this.tablesData};
      this.loading = false;
    })
      .catch((error) => {
        this.cm.showNotification('danger', error);
      });
  }

  pageSize = 50;
  pageNumber = 1;
  count = 0;
  loading = true;

  _pageChange(page) {
    this.pageSize = page.pageSize;
    this.pageNumber = page.pageNumber;
    this.getProducts();
  }

  _addNew(formValues) {
    // formValues['type'] = this.chargingBoxTypeObj;
    debugger
    if (formValues['type'])
      formValues['type'] = formValues['type']['object'];
    else formValues['type'] = null;
    this.addApkService.postParseData(formValues, this.formData, this.objectName).then(success => {
      this.cm.showNotification('success', success);
      this.overlayService.closeOverlay();
      this.getProducts();
    }, (error) => {
      this.cm.showNotification('danger', error);
    });
    // this.cm.cloudFunction('addChargingBox', formValues).then(success => {
    //   this.overlayService.closeOverlay();
    //   this.cm.showNotification('success', success);
    //   this.getProducts(true);
    // }, error => {
    //   this.cm.showNotification('danger', error);
    // });
  }

  _update(object) {
    if (object.formValues['type'])
      object.formValues['type'] = object.formValues['type']['object'];
    // object.formValues['type'] = this.chargingBoxTypeObj;
    this.cm.getParseDataById(this.objectName, object.primaryKey).then((product) => {
      this.formData.map(formObj => {
        if (object.formValues[formObj['key']]) //pointer object optional
          product.set(formObj['key'], object.formValues[formObj['key']]);
      });
      product.save().then(updated => {
        this.getProducts();
        this.cm.showNotification('success', updated);
        this.overlayService.closeOverlay();
      }, (error2) => {
        this.cm.showNotification('danger', error2);
      });
    }, (error1) => {
      this.cm.showNotification('danger', error1);
    });
  }

  _delete(primaryKey) {
    // debugger
    this.cm.getParseDataById('Apk', primaryKey).then((object) => {
      object.destroy().then((success) => {
        this.cm.showNotification('success', success);
        this.getProducts();
      }, (error) => {
        // The delete failed.
        this.cm.showNotification('danger', error);
        // error is a Parse.Error with an error code and message.
      });
    });
  }

  ngOnInit() {
  }

  filters = {};
  _applyFilter(event) {
    this.filters = {...event};
    this.getProducts();
  }

}

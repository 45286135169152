import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {CommonService} from "../shared/common/common-services/common-service";

// declare const Parse: any;


@Injectable()
export class AddGlobalConfigService {
  public $pointerData = new Subject<any>();

  getPointerData(): Observable<any> {
    return this.$pointerData.asObservable();
  }
  constructor(private http: HttpClient, private cm: CommonService) {
  }

  getParse(objectName, pointers) {
    const Product = this.cm.ParseJs.Object.extend(objectName);
    const query = new this.cm.ParseJs.Query(Product);
    query.descending('createdAt');
    // query.relation('videos');
    pointers.map(pointer => {
      query.include(pointer['relationName']);
    });
    return query.find();
  }
  getParseData(result, pointers) {
    let temp: any = {};
      temp = {...temp, ...this.formatResult(result, 'id')};
      pointers.map(pointer => {
        if (pointer['relation']) {
          temp[pointer['relationName']] = result.relation(pointer['relationName']).query();
        } else
          if (result.attributes[pointer['relationName']]) {
          temp[pointer['relationName']] = this.formatResult(result.attributes[pointer['relationName']], 'id');
        }
      });
    return temp;
  }
  formatResult(result, id?) {
    let temp: any = {};
    temp = {...result.attributes};
    temp['object'] = result;
    if (id)
      temp[id] = result.id;
    return temp;
  }

  postParseData(data, parentForm, objectName, relationArray) {
    const _ObjectParent = this.cm.ParseJs.Object.extend(objectName);
    const _objectParent = new _ObjectParent();
    const keys = Object.keys(data);
    parentForm.map(formObj => {
      _objectParent.set(formObj['key'], data[formObj['key']]);
    });
    if (relationArray.length) {
      const relation = _objectParent.relation('videos');
      relationArray.map(relationobj => {
        relation.add(relationobj);
      });
    }
    return _objectParent.save();
  }
}

import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {AddGlobalConfigService} from "./add-global-config.service";
import {CommonService} from "../shared/common/common-services/common-service";
import {OverlayService} from "../shared/common/overlay/overlay.service";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-add-global-config',
  templateUrl: './add-global-config.component.html',
  styleUrls: ['./add-global-config.component.scss']
})
export class AddGlobalConfigComponent implements OnInit {
  tablesData: any = {};
  objectName = 'GlobalConfig';
  pointers = [
    {
      objectName: 'Apk',
      relationName: 'apk',
    },
    {
      objectName: 'Apk',
      relationName: 'apks',
      relation: true
    },
    {
      objectName: 'NetworkConfig',
      relationName: 'network',
    },
    {
      objectName: 'Video',
      relationName: 'videos',
      relation: true
    }
  ];
  videoObjArray: any = [];
  videoOptionsArray: any;
  apkObj: any = {};
  networkObj: any = {};
  globalConfig: any = {};
  formData = [ // deviceName, boxCode, bcode and deviceNickName
    {
      key: 'name',
      placeholder: 'Name',
      value: '',
      label: '', //no use
      type: 'text',
    },
    {
      key: 'timeData',
      placeholder: 'Time Data',
      value: '',
      label: '', //no use
      type: 'text',
    },
    {
      key: 'mill',
      placeholder: 'Mill',
      value: '',
      label: '', //no use
      type: 'text',
    },
    {
      key: 'network',
      placeholder: 'Network',
      value: '',
      label: '',
      type: 'select',
      isOptionsPromise: true,
      selected: true,
      options: this.addGlobalConfigService.getParse('NetworkConfig', []),
      // change: (prop) => {
      //   this.networkObj = prop['object'];
      // },
      viewValue: 'wname',
      viewKey: 'id'
    },
    // {
    //   key: 'apk',
    //   placeholder: 'Apk',
    //   value: '',
    //   label: '',
    //   type: 'select',
    //   isOptionsPromise: true,
    //   selected: true,
    //   options: this.addGlobalConfigService.getParse('Apk', []),
    //   viewValue: 'apkVersion',
    //   viewKey: 'id'
    // },
    {
      key: 'videos',
      placeholder: 'Videos',
      value: '',
      label: '',
      type: 'multiselect',
      isOptionsPromise: true,
      // selected: true,
      options: this.addGlobalConfigService.getParse('Video', []),
      change: (prop, options) => {
        this.videoOptionsArray = options;
      },
      viewValue: 'url',
      viewKey: 'id'
    },
  ];
  form: FormGroup;
  apksform: FormGroup;
  counter = 0;

  constructor(private _fb: FormBuilder, private cm: CommonService, private addGlobalConfigService: AddGlobalConfigService, private vc: ViewContainerRef,
              private overlayService: OverlayService) {
    this.form = this._fb.group({});
    this.apksform = this._fb.group({});
    this.tablesData = this.cm.getGcAttributes();
    this.getProducts();
    this.getTypes();
    this.addGlobalConfigService.getPointerData().subscribe(data => {
      this.tablesData.data = data;
      this.tablesData = {...this.tablesData};
      if (data) {
        this.globalConfig = data;
        this.form.patchValue(this.globalConfig);
      }
    });
  }

  getProducts(navigate?) {
    // this.addGlobalConfigService.get()
    this.addGlobalConfigService.getParse(this.objectName, this.pointers).then(results => {
      // results contains a weight / rank in result.get('score')
      if (results.length) {
        this.globalConfig = this.addGlobalConfigService.getParseData(results[0], this.pointers);
        // this.form.patchValue(this.globalConfig);
        this.fetchVideos();
        this.fetchApks();
      }
      else this.globalConfig = null;
    })
      .catch((error) => {
        this.cm.showNotification('danger', error);
      });
  }

  async fetchVideos() {
    const arrrelatedObject = [];
    await this.globalConfig['videos'].each((relatedObject) => {
      console.log(relatedObject);
      arrrelatedObject.push(this.addGlobalConfigService.formatResult(relatedObject, 'id'));
    });
    const object = {}
    object['videos'] = arrrelatedObject;
    this.globalConfig = {...this.globalConfig, ...object};
    this.form.patchValue(this.globalConfig);
  }

  apksButton = false;

  async fetchApks() {
    const arrrelatedObject = [];
    await this.globalConfig['apks'].each((relatedObject) => {
      arrrelatedObject.push(this.addGlobalConfigService.formatResult(relatedObject, 'id'));
    });
    const object = {}
    object['apks'] = arrrelatedObject;
    this.globalConfig = {...this.globalConfig, ...object};
    this.apksButton = true;
  }

  _addNew() {
    const formValues = this.form.value;
    if (formValues['apk'])
      formValues['apk'] = formValues['apk']['object'];
    if (formValues['network'])
      formValues['network'] = formValues['network']['object'];
    formValues['videos'].map(video => {
      this.videoObjArray.push(video['object']);
    });
    this.addGlobalConfigService.postParseData(formValues, this.formData, this.objectName, this.videoObjArray).then(success => {
      this.cm.showNotification('success', success);
      this.overlayService.closeOverlay();
      this.getProducts();
    }, (error) => {
      this.cm.showNotification('danger', error);
    });
  }

  updating = false;

  _update() {
    this.updating = true;
    const object = {formValues: this.form.value, primaryKey: this.globalConfig['id']};
    if (object.formValues['apk'])
      object.formValues['apk'] = object.formValues['apk']['object'];
    if (object.formValues['network'])
      object.formValues['network'] = object.formValues['network']['object'];
    // this.cm.getParseDataById(this.objectName, object.primaryKey).then((product) => {
    const product = this.globalConfig['object'];
    this.formData.map(formObj => {
      if (formObj['key'] !== 'videos')
        product.set(formObj['key'], object.formValues[formObj['key']]);
    });
    //apks relation
    const relationApks = product.relation('apks');
    const apksKeys = Object.keys(this.apksform.value);
    debugger
    apksKeys.map(key => {
      if (this.apksform.value[key]) {
        relationApks.add(this.apksform.value[key]['object']);
        debugger
      }
    });
    // videos relation
    const relation = product.relation('videos');
    this.videoOptionsArray.map(video => {
      relation.remove(video['object']);
    });
    object.formValues['videos'].map(video => {
      relation.add(video['object']);
    });
    product.save().then(updated => {
      this.cm.showNotification('success', updated);
      this.overlayService.closeOverlay();
      this.getProducts();
      this.updating = false;
    }, (error2) => {
      this.cm.showNotification('danger', error2);
      this.updating = false;
    });
    this.getProducts();
    // }, (error1) => {
    //   this.cm.showNotification('danger', error1);
    // });
  }

  _delete(primaryKey) {
    this.cm.getParseDataById(this.objectName, primaryKey).then((object) => {
      object.destroy().then((success) => {
        this.cm.showNotification('success', success);
        this.getProducts();
      }, (error) => {
        // The delete failed.
        this.cm.showNotification('danger', error);
        // error is a Parse.Error with an error code and message.
      });
    });
  }

  addApks() {
    this.apksform.value;
    debugger
    this.overlayService.closeAllOverlays();
  }

  apkFormData = [];
  typeData = [];

  getTypes() {
    this.cm.getParse('ChargingBoxType', []).then(types => {
      debugger
      types.results.map((type, index) => {
        this.typeData.push(this.addGlobalConfigService.formatResult(type, 'id'))
        this.apkFormData.push({
          key: type['id'],
          placeholder: 'Apk',
          value: '',
          label: '',
          type: 'select',
          isOptionsPromise: true,
          selected: true,
          options: this.cm.getParseFind('Apk', 'type', type),
          viewValue: 'apkVersion',
          viewKey: 'id'
        });
      });
      // apksP = types.results.map(type => {
      //   return this.cm.getParseFind('Apk', 'type', type);
      // });
      // Promise.all(apksP).then(apks => {
      //   apks.map(apk => {
      //     if (apk.length) {
      //       apk.map((apkObject, index) => {
      //         const object = [];
      //         // this.addGlobalConfigService.formatResult(apkObject, 'id')
      //         object.push();
      //         this.apkFormData.push(object);
      //       });
      //     }
      //   });
      //   this.apkFormData;
      //   debugger
      // });
    });
  }

  @ViewChild('crud', {static: false}) crud;

  openCrud(data = null): void {
    this.overlayService.open({
      component: this.crud,
      data,
      viewContainerRef: this.vc,
      backdropClass: 'dark-backdrop',
      panelClass: 'dialog-panel',
      width: '52.61%',
      height: '88%',
    });
    setTimeout(() => {
      debugger
      this.globalConfig.apks.map(apk => {
        debugger
        this.apksform.get(apk['type']['id']).patchValue(apk);
        debugger
      });
    }, 1000)
  }

  ngOnInit() {
  }

  restartAllDevices() {
    this.cm.cloudFunction('restartAllDevices', {}).then(success => {
      this.cm.showNotification('success', success);
    }, error => {
      this.cm.showNotification('danger', error);
    });
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonService} from "../shared/common/common-services/common-service";
import {ListCustomerService} from "./list-customer.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-list-customer',
  templateUrl: './list-customer.component.html',
  styleUrls: ['./list-customer.component.scss']
})
export class ListCustomerComponent implements OnInit, OnDestroy {
  userForm = [
    {
      key: 'email',
      placeholder: 'Email',
      value: '',
      label: 'Email:',
      type: 'text'
    },
    {
      key: 'username',
      placeholder: 'Username',
      value: '',
      label: 'Username:',
      type: 'text'
    },
    {
      key: 'password',
      placeholder: 'Password',
      value: '',
      label: 'Password:',
      type: 'password'
    }
  ];
  addressForm = [
    // {
    //   key: 'id',
    //   placeholder: 'Id',
    //   value: '',
    //   label: 'Id:',
    //   type: 'text'
    // },
    {
      key: 'addressLine1',
      placeholder: 'addressLine1',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'addressLine2',
      placeholder: 'addressLine1',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'city',
      placeholder: 'city',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'state',
      placeholder: 'state',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'country',
      placeholder: 'country',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'zip',
      placeholder: 'zip',
      value: '',
      label: '',
      type: 'text'
    }
  ];
  formDataDetailPage1 = [
    {
      key: 'firstName',
      placeholder: 'customer_name_first',
      value: '',
      label: 'customer_name_first:',
      type: 'text',
      disabled: true
    },
    {
      key: 'lastName',
      placeholder: 'customer_name_last',
      value: '',
      label: 'customer_name_last:',
      type: 'text',
      disabled: true
    },
    {
      key: 'customer_number',
      placeholder: 'customer_number',
      value: '',
      label: 'customer_number:',
      type: 'text',
      disabled: true
    },
    {
      key: 'app_download_date',
      placeholder: 'app_download_date',
      value: '',
      label: 'app_download_date:',
      type: 'text',
      disabled: true
    },
    {
      key: 'has_payment_info',
      placeholder: 'has_payment_info',
      value: '',
      label: 'has_payment_info:',
      type: 'toggle',
      disabled: true
    },
    {
      key: 'has_rented',
      placeholder: 'has_rented',
      value: '',
      label: 'has_rented:',
      type: 'toggle',
      disabled: true
    },
    {
      key: 'first_rental_date',
      placeholder: 'first_rental_date',
      value: '',
      label: 'first_rental_date:',
      type: 'text',
      disabled: true
    },
    {
      key: 'last_rental_date',
      placeholder: 'last_rental_date',
      value: '',
      label: 'last_rental_date:',
      type: 'text',
      disabled: true
    },
    {
      key: 'rentals_lifetime',
      placeholder: 'rentals_lifetime',
      value: 0,
      label: 'rentals_lifetime:',
      type: 'number',
      disabled: true
    },
    {
      key: 'rentals_7d',
      placeholder: 'rentals_7d',
      value: 0,
      label: 'rentals_7d:',
      type: 'number',
      disabled: true
    },
    {
      key: 'rentals_30d',
      placeholder: 'rentals_30d',
      value: 0,
      label: 'rentals_30d:',
      type: 'number',
      disabled: true
    },
    {
      key: 'lifetime_spend',
      placeholder: 'lifetime_spend',
      value: 0,
      label: 'lifetime_spend:',
      type: 'number',
      disabled: true
    },
    {
      key: 'push_enabled',
      placeholder: 'push_enabled',
      value: '',
      label: 'push_enabled:',
      type: 'toggle',
      disabled: true
    },
    {
      key: 'is_renting',
      placeholder: 'is_renting',
      value: '',
      label: 'is_renting:',
      type: 'toggle',
      disabled: true
    },

  ];
  formDataDetailPage = [
    {
      key: 'username',
      placeholder: 'username',
      value: '',
      label: '',
      type: 'text',
      disabled: true
    },
    {
      key: 'emailVerified',
      placeholder: 'emailVerified',
      value: '',
      label: '',
      type: 'text',
      disabled: true
    },
    {
      key: 'language',
      placeholder: '',
      value: '',
      label: '',
      type: 'text',
      disabled: true
    },
    {
      key: 'email',
      placeholder: '',
      value: '',
      label: '',
      type: 'text',
      disabled: true
    },
  ];
  pointers = [
  ];
  formData = [
    // {
    //   key: 'user',
    //   placeholder: 'User',
    //   label: 'User:',
    //   type: 'select',
    //   viewValue: 'username',
    //   viewKey: 'id',
    //   options: this.cm.getParse('User'),
    //   form: this.userForm
    // },
    {
      key: 'customer_id',
      placeholder: 'customer_id',
      value: '',
      label: 'customer_id:',
      type: 'text',
      // disabled: true
    },
    {
      key: 'firstName',
      placeholder: 'customer_name_first',
      value: 'Joe',
      label: 'customer_name_first:',
      type: 'text',
      // disabled: true
    },
    {
      key: 'lastName',
      placeholder: 'customer_name_last',
      value: 'Smith',
      label: 'customer_name_last:',
      type: 'text',
      // disabled: true
    },
    {
      key: 'customer_number',
      placeholder: 'customer_number',
      value: '2016936324',
      label: 'customer_number:',
      type: 'text',
      // disabled: true
    },
    {
      key: 'app_download_date',
      placeholder: 'app_download_date',
      value: '08-12-2019',
      label: 'app_download_date:',
      type: 'text',
      // disabled: true
    },
    {
      key: 'has_payment_info',
      placeholder: 'has_payment_info',
      value: 'true',
      label: 'has_payment_info:',
      type: 'toggle',
      // disabled: true
    },
    {
      key: 'has_rented',
      placeholder: 'has_rented',
      value: 'true',
      label: 'has_rented:',
      type: 'toggle',
      // disabled: true
    },
    {
      key: 'first_rental_date',
      placeholder: 'first_rental_date',
      value: '08-12-2019',
      label: 'first_rental_date:',
      type: 'text',
      // disabled: true
    },
    {
      key: 'last_rental_date',
      placeholder: 'last_rental_date',
      value: '08-12-2019',
      label: 'last_rental_date:',
      type: 'text',
      // disabled: true
    },
    {
      key: 'rentals_lifetime',
      placeholder: 'rentals_lifetime',
      value: 11,
      label: 'rentals_lifetime:',
      type: 'number',
      // disabled: true
    },
    {
      key: 'rentals_7d',
      placeholder: 'rentals_7d',
      value: 5,
      label: 'rentals_7d:',
      type: 'number',
      // disabled: true
    },
    {
      key: 'rentals_30d',
      placeholder: 'rentals_30d',
      value: 15,
      label: 'rentals_30d:',
      type: 'number',
      // disabled: true
    },
    {
      key: 'lifetime_spend',
      placeholder: 'lifetime_spend',
      value: 10.99,
      label: 'lifetime_spend:',
      type: 'number',
      // disabled: true
    },
    {
      key: 'push_enabled',
      placeholder: 'push_enabled',
      value: 'true',
      label: 'push_enabled:',
      type: 'toggle',
      // disabled: true
    },
    {
      key: 'is_renting',
      placeholder: 'is_renting',
      value: 'true',
      label: 'is_renting:',
      type: 'toggle',
      // disabled: true
    },

  ];
  tablesData: any = {};
  objectName = 'User';

  constructor(private cm: CommonService, private listCustomerService: ListCustomerService, private router: Router) {
    this.tablesData = this.cm.getCustomerAttributes();
    this.getProducts();
  }

  getProducts() {
    this.loading = true;
    this.listCustomerService.getParse(this.objectName, this.pointers, this.pageNumber, this.pageSize, this.filters).then(result => {
      // results contains a weight / rank in result.get('score')
      this.count = result.count;
      this.tablesData.data = this.listCustomerService.getParseData(result.results, this.pointers);
      console.log(this.tablesData.data)
      this.tablesData = {...this.tablesData};
      this.loading = false;
      debugger
    }, error => {
      this.cm.showNotification('danger', error);
    });
  }

  detailData: any = {};

  _detail(row) {
    this.detailData = row;
    this.router.navigate(['/customer-detail']);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.listCustomerService.setCustomerDetail(this.detailData);
    }, 1000);
  }

  pageSize = 50;
  pageNumber = 1;
  count = 0;
  loading = true;

  _pageChange(page) {
    this.pageSize = page.pageSize;
    this.pageNumber = page.pageNumber;
    this.getProducts();
  }

  filters = {};

  _applyFilter(event) {
    this.filters = {...event};
    this.getProducts();
  }
}

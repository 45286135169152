import { Injectable, Inject, OnInit, Injector, ComponentRef, InjectionToken, TemplateRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, TemplatePortal } from '@angular/cdk/portal';

import { OverlayComponent } from './overlay.component';

import { OverlayRefMain } from './overlay-ref';
import { DIALOG_COMPONENT, DIALOG_DATA } from './overlay.tokens';
import { ComponentType } from '@angular/cdk/portal';
import { Subject } from 'rxjs';

interface DialogConfig {
  panelClass?: string;
  disableClose?: boolean;
  hasBackdrop?: boolean;
  backdropClass?: string;
  component?: any;
  viewContainerRef?: any;
  data?: any;
  width?: string;
  height?: string;
  renderElem?: any,
  position?: any,
  top?: string
}

const DEFAULT_CONFIG: DialogConfig = {
  hasBackdrop: true,
  backdropClass: '',
  panelClass: '',
  disableClose: true,
  width: '100%',
  height: '100%',
  component: null,
  viewContainerRef: null,
  data: null,
  renderElem: null,
  position: null,
  top: null
};

@Injectable()
export class OverlayService {
  overlayCreated: Subject<boolean> = new Subject();
  overlayRefs: OverlayRef[] = [];
  set overlayRef(overlay) {
    this.overlayRefs.push(overlay);
  }
  get overlayRef() {
    return this.overlayRefs[this.overlayRefs.length - 1];
  }
  get allOverlayRefs() {
    return this.overlayRefs;
  }

  constructor(
    private injector: Injector,
    private overlay: Overlay) { }

  componentCreated() {
    this.overlayCreated.next(true);
  }

  /* private getOverlayConfig(config) {
    const state = {
      ...DEFAULT_CONFIG,
      scrollStrategy: config.scrollStrategy || this.overlay.scrollStrategies.block(),
    };

    return state;
  } */

  open(config: DialogConfig = {}) {
    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    this.overlayRef = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef = new OverlayRefMain(this.overlayRef, this);

    const overlayComponent = this.attachDialogContainer(this.overlayRef, dialogConfig, dialogRef);

    this.overlayRef.backdropClick().subscribe(_ => {
      if (!dialogConfig.disableClose) {
        dialogRef.close();
      }
    });

    return dialogRef;
  }

  public closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRefs.pop();
    }
  }

  public closeAllOverlays() {
    this.allOverlayRefs.forEach(overlay => {
      overlay.dispose();
    })
    this.overlayRefs.length = 0;
  }

  private createOverlay(config: DialogConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: DialogConfig, dialogRef: OverlayRefMain) {
    if (config.component instanceof TemplateRef) {
      const portal = new TemplatePortal(
        config.component,
        config.viewContainerRef,
        {$implicit: dialogRef, data: config.data},
      );
      const containerRef = overlayRef.attach(portal);
    } else {
      const injector = this.createInjector(config, dialogRef);
      const containerPortal = new ComponentPortal(OverlayComponent, null, injector);
      const containerRef: ComponentRef<OverlayComponent> = overlayRef.attach(containerPortal);
    }
  }

  public updateOverlayPosition() {
    this.overlayRef.updatePosition();
  }

  private createInjector(config: DialogConfig, dialogRef: OverlayRefMain): PortalInjector {
    const injectionTokens = new WeakMap();

    const injector = new PortalInjector(this.injector, new WeakMap().set(DIALOG_DATA, config.data).set(OverlayRefMain, dialogRef));
    const nestedComPortal = new ComponentPortal(config.component, null, injector);
    console.log('Config data..............');
    console.log(config.data);
    // const nestedComPortal = new ComponentPortal(config.component);

    injectionTokens.set(OverlayRefMain, dialogRef);
    injectionTokens.set(DIALOG_COMPONENT, nestedComPortal);
    injectionTokens.set(DIALOG_DATA, config.component);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: DialogConfig): OverlayConfig {
    let positionStrategy;
    if (config.position) {
      let positionData = [];
      if (config.position === 'start') {
        positionData = [{ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }];
      } else {
        positionData = [{ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }];
      }
      /* positionStrategy = this.overlay.position().connectedTo(config.renderElem,
        {
          originX: 'end',
          originY: 'bottom'
        },
        {
          overlayX: 'end',
          overlayY: 'top'
        }
      ); */
      positionStrategy = (this.overlay.position().connectedTo as any)(config.renderElem, ...positionData);
    } else {
      positionStrategy = this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically().width(config.width).height(config.height);
        if (config.top) positionStrategy.top(config.top);
    }

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      width: config.width,
      height: config.height,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }
}

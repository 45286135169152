import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';

import { AppComponent } from './app.component';
import { AdminModule } from './admin/admin.module';
import { AuthHeaderInterceptor } from './interceptors/header.interceptor';
import { CatchErrorInterceptor } from './interceptors/http-error.interceptor';

import { AppRoutingModule } from './app-routing/app-routing.module';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import {AdminLayoutComponent} from './shared/layouts/admin-layout/admin-layout.component';
import {AdminLayoutModule} from './shared/layouts/admin-layout/admin-layout.module';
import {CommonsModule} from './shared/common/common-module';
import {AgmCoreModule} from '@agm/core';
import {ListVenueComponent} from "./list-venue/list-venue.component";
// import { AddGlobalConfigComponent } from './add-global-config/add-global-config.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    AdminLayoutComponent,
    ListVenueComponent
  ],
  imports: [
    BrowserModule,
    CommonsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    AuthModule,
    AdminModule,
    AppRoutingModule,
    AdminLayoutModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCgzZLKci027eBRixTxgy2Vb571nRJ4Vfw',
      libraries: ['places']
    })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHeaderInterceptor,
    multi: true,
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: CatchErrorInterceptor,
    multi: true,
  }],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

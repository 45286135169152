import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {EjectBatteryService} from "../../eject-battery/eject-battery.service";
import {CommonService} from "../../shared/common/common-services/common-service";
import {ChargingBoxService} from "../charging-box.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {OverlayService} from "../../shared/common/overlay/overlay.service";

@Component({
  selector: 'app-charging-box-detail',
  templateUrl: './charging-box-detail.component.html',
  styleUrls: ['./charging-box-detail.component.scss']
})
export class ChargingBoxDetailComponent implements OnInit {
  pointers = [
    {
      objectName: 'ChargingBox',
      relationName: 'chargingBox',
      // formData: this.addressForm
    },
    {
      objectName: 'Address',
      relationName: 'address',
      // formData: this.addressForm
    }
  ];
  objectName = 'ChargingBoxStatus'; // 'ChargingBox';
  objectNameChargingBox = 'ChargingBox'; // 'ChargingBox';
  ChargingBox: any = {};
  ChargingBoxStatus: any = {};
  chargingBoxId;
  dataLoaded = false;
  networkConfigFormData = {
    key: 'network',
    placeholder: 'Network',
    value: '',
    label: '',
    type: 'select',
    validation: {
      required: true
    },
    errorMessage: {
      required: 'Please enter Network',
    },
    isOptionsPromise: true,
    options: this.chargingBoxService.getParse('NetworkConfig', []),
    viewValue: 'wname',
    viewKey: 'id'
  }
  formData = {
    key: 'apk',
    placeholder: 'Select Apk Version',
    value: '',
    label: '',
    type: 'select',
    validation: {
      required: true
    },
    errorMessage: {
      required: 'Please enter Product Key',
    },
    disabled: true,
    isOptionsPromise: false,
    selected: true,
    options: [],
    viewValue: 'apkVersion',
    viewKey: 'id'
  };
  @ViewChild('crud', {static: false}) crud;

  form: FormGroup;

  constructor(private route: ActivatedRoute, private chargingBoxService: ChargingBoxService,
              private ejectBatteryService: EjectBatteryService,
              private _fb: FormBuilder, private overlaySvc: OverlayService, private vc: ViewContainerRef, private cm: CommonService, private router: Router) {
      this.form = this._fb.group({});
// chargingBoxService.getchargingBoxSub().subscribe(event => {
    //   if (this.chargingBoxId)
    //     this.getProducts(this.chargingBoxId);
    // });
  }

  ngOnInit() {
    this.route.queryParams
      .filter(params => params.id)
      .subscribe(params => {
        console.log(params); // {order: "popular"}

        this.chargingBoxId = params.id;
        // this.cm.getParseDataById('ChargingBox', this.chargingBoxId).then(ch => {
        //   this.chargingBoxService.getParseFindChargingBoxStatus(this.objectName, 'chargingBox', ch, this.pointers).then(result => {
        //     const data = this.chargingBoxService.getParseDataChargingBoxStatus(result, this.pointers);
        //     this.ChargingBoxStatus = data[0];
        //   });
        // })
        this.getProducts(this.chargingBoxId);
      });
  }

  getProducts(chargingBoxId) {
    debugger
    this.ejectBatteryService.getParseById(this.objectNameChargingBox, chargingBoxId, this.pointers).then(result => {
      debugger //locationName
      if (result.attributes['type'])// for please add an option to install apk directly to device.
        this.changeApkOptions(result.attributes['type']);
      const data = this.ejectBatteryService.getParseDataById(result, this.pointers);
      this.ChargingBox = data[0];
      Promise.all(data[1]).then(batteries => {
        batteries.map((battery: Array<{}>, index) => {
          if (battery.length) {
            battery.map((btr) => {
              this.ChargingBox['battery'].push(this.ejectBatteryService.formatResult(btr, 'batteryId'));
            })
            this.ejectBatteryService.formatResult(battery, 'batteryId');
          }
        });
        this.ChargingBox;
        this.chargingBoxService.getParseFindChargingBoxStatus(this.objectName, 'chargingBox', result, this.pointers).then(chst => {
          const data = this.chargingBoxService.getParseDataChargingBoxStatus(chst, this.pointers);
          this.ChargingBoxStatus = data[0];
          this.cm.getParseFind(this.objectName, 'chargingBox', result).then(apkData => {
            const apkdata = this.chargingBoxService.formatResult(apkData);
            this.ChargingBoxStatus = {...apkdata, ...this.ChargingBoxStatus};
          })
          debugger
          this.dataLoaded = true;
        });
        // this.sortBatteries('btBay');
        // debugger;
      });
    });
  }

  progressBarClass(value) {
    if (value >= 80) return 'pb-success';
    if (value < 80 && value >= 50) return 'pb-warning';
    if (value < 50) return 'pb-danger';
    return '';
  }

  progressBarPercentageClass(value) {
    if (value >= 80) return 'text-success';
    if (value < 80 && value >= 50) return 'text-warning';
    if (value < 50) return 'text-danger';
    return '';
  }

  colorClass(value) {
    if (value >= 80) return 'success';
    if (value < 80 && value >= 50) return 'warning';
    if (value < 50) return 'danger';
    return '';
  }

  _delete() {
    // debugger
    this.cm.cloudFunction('deleteChargingBox', {chargingBoxId: this.chargingBoxId}).then(success => {
      this.router.navigate(['/charging-box']);
      this.cm.showNotification('success', success);
    }, error => {
      this.cm.showNotification('danger', error);
    });
  }

  ejectBattery(batteryBay, chargingBoxId) {
    debugger
    this.ejectBatteryService.ejectBattery({forceEject: true, batteryBay: batteryBay, chargingBoxId: chargingBoxId});
  }

  simulateEjectBatteryByUser(chargingBoxId) {
    this.ejectBatteryService.ejectBattery({chargingBoxId: chargingBoxId});
  }

  ejectBatteryAll(chargingBoxId, order) {
    this.cm.cloudFunction('ejectAllBatteries', {chargingBoxId, ejectOrder: order}).then(success => {
      this.cm.showNotification('success', success);
    }, error => {
      this.cm.showNotification('danger', error);
    });
  }

  ejectCabinetsAll(chargingBoxId) {
    this.cm.cloudFunction('ejectAllCabinets', {chargingBoxId}).then(success => {
      this.cm.showNotification('success', success);
    }, error => {
      this.cm.showNotification('danger', error);
    });
  }

  restartChargingBox(chargingBoxId) {
    this.cm.cloudFunction('restartChargingBox', {chargingBoxId}).then(success => {
      this.cm.showNotification('success', success);
    }, error => {
      this.cm.showNotification('danger', error);
    });
  }
  getLogs(chargingBoxId) {
    this.cm.cloudFunction('fetchDeviceLogs', {chargingBoxId: chargingBoxId}).then(success => {
      this.cm.showNotification('success', success);
    }, error => {
      this.cm.showNotification('danger', error);
    });
  }


  getButtonText(batteryId) {
    const objectChr = this.ChargingBox['battery'].find(x => x['batteryId'] === batteryId);
    return batteryId ? 'Eject Battery (Cabinet:' + objectChr['btBay'] + ')' : 'Eject Battery';
  }

  sortBatteries(field) {
    this.ChargingBox['battery'].sort((a, b) => {
      return a[field] - b[field];
    });
  }

  batteriesOnline(btAllN) {
    return Array(parseInt(btAllN, 10)).fill(3); // .map((x,i)=>i); // [0,1,2,3,4]
  }

  batteryPercentage(btBay) {
    const battery = this.ChargingBox['battery'].find(obj => obj['btBay'] === btBay);
    if (battery) return battery['bteq'];
    else return '';
  }

  batterySerialNumber(btBay) {
    const battery = this.ChargingBox['battery'].find(obj => obj['btBay'] === btBay);
    if (battery) return battery['btSn'];
    else return '';
  }

  openCrud(data = null, component): void {
    this.overlaySvc.open({
      component: component,
      data,
      viewContainerRef: this.vc,
      backdropClass: 'dark-backdrop',
      panelClass: 'dialog-panel',
      width: '52.61%',
      height: '88%',
    });
  }

  crudType = '';
  changeApkOptions(fieldValue) {
    this.cm.getParseFind('Apk', 'type', fieldValue).then(results => {
      this.formData['options'] = this.cm.getOptionsData(results, 'id');
      this.formData['disabled'] = false;
      this.formData = {...this.formData};
    });
  }

  openCrudUpdate(crudType) {
    this.crudType = crudType;
    // setTimeout(() => {
      this.openCrud(null, this.crud);
    // }, 1000);
  }

  updateApkVersion() {
    this.cm.validateAllFormFields(this.form);
    if (this.form.valid) {
      if (this.crudType === 'apk') {
        const apkversion = this.form.value['apk']['id'];
        this.cm.cloudFunction('updateDeviceApk', {
          chargingBoxId: this.chargingBoxId,
          apkId: apkversion
        }).then(success => {
          this.overlaySvc.closeOverlay();
          this.cm.showNotification('success', success);
          this.getProducts(true);
        }, error => {
          this.cm.showNotification('danger', error);
        });
      } else {
        const network = this.form.value['network']['id'];
        this.cm.cloudFunction('changeDeviceNetwork', {
          chargingBoxId: this.chargingBoxId,
          networkConfigId: network
        }).then(success => {
          this.overlaySvc.closeOverlay();
          this.cm.showNotification('success', success);
          this.getProducts(true);
        }, error => {
          this.cm.showNotification('danger', error);
        });
      }
    }
  }
}

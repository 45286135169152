import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {CommonService} from "../shared/common/common-services/common-service";
import {OverlayService} from "../shared/common/overlay/overlay.service";
import {AddVenueService} from "./add-venue.service";

@Component({
  selector: 'app-add-venue',
  templateUrl: './add-venue.component.html',
  styleUrls: ['./add-venue.component.scss']
})
export class AddVenueComponent implements OnInit {
  formData = [
    {
      key: 'addressLine1',
      placeholder: 'addressLine1',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'addressLine2',
      placeholder: 'addressLine2',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'city',
      placeholder: 'city',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'state',
      placeholder: 'state',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'country',
      placeholder: 'country',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'zip',
      placeholder: 'zip',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'locationName',
      placeholder: 'Location Name',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'openingTimings',
      placeholder: 'Opening Timings',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'entity_ref',
      placeholder: 'Entity Ref',
      value: '',
      label: '',
      type: 'text'
    },
    {
      key: 'location',
      placeholder: 'Location',
      lat: 34.0535063,
      lng: -118.46487589999998,
      type: 'location'
    }
  ];
  tablesData: any = {};
  objectName = 'Address';
  pointers = []
  location: any = {};

  form: FormGroup;

  constructor(private cm: CommonService, private chargingBoxService: AddVenueService, private overlayService: OverlayService) {
    this.tablesData = this.cm.getVenueAttributes();
    this.getProducts();
  }

  getProducts() {
    this.loading = true;
    this.cm.getParse(this.objectName, this.pointers, this.pageNumber, this.pageSize, this.filters).then(result => {
      this.count = result.count;
      // results contains a weight / rank in result.get('score')
      this.tablesData.data = this.chargingBoxService.getParseData(result.results);
      this.tablesData = {...this.tablesData};
      this.loading = false;
    })
      .catch(function (error) {
        this.cm.showNotification('danger', error);
      });
  }

  pageSize = 50;
  pageNumber = 1;
  count = 0;
  loading = true;

  _pageChange(page) {
    this.pageSize = page.pageSize;
    this.pageNumber = page.pageNumber;
    this.getProducts();
  }

  async _addNew(formValues) {
    formValues = {...formValues, ...{location: this.chargingBoxService.getGeoPoint(formValues['lat'], formValues['lng'])}};
    this.chargingBoxService.postParseData(formValues, this.formData, this.objectName).then(success => {
      this.overlayService.closeOverlay();
      this.cm.showNotification('success', success);
      this.getProducts();
    }, (error) => {
      this.cm.showNotification('danger', error);
    });
  }

  _update(object) {
    object.formValues = {...object.formValues, ...{location: this.chargingBoxService.getGeoPoint(object.formValues['lat'], object.formValues['lng'])}};
    this.cm.getParseDataById(this.objectName, object.primaryKey).then((product) => {
      this.formData.map(formObj => {
        product.set(formObj['key'], object.formValues[formObj['key']]);
      });
      product.save().then(updated => {
        this.cm.showNotification('success', updated);
        this.overlayService.closeOverlay();
        this.getProducts();
      }, (updateerror) => {
        this.cm.showNotification('danger', updateerror);
      });
      this.getProducts();
    }, (error) => {
      debugger
    });
  }

  _delete(primaryKey) {
    // debugger
    this.cm.getParseDataById(this.objectName, primaryKey).then((myObject) => {
      myObject.destroy().then((deleted) => {
        // The object was deleted from the Parse Cloud.
        this.getProducts();
        this.cm.showNotification('success', deleted);
      }, (deleteerror) => {
        // The delete failed.
        this.cm.showNotification('danger', deleteerror);
        // error is a Parse.Error with an error code and message.
      });
    }, error => {
      this.cm.showNotification('danger', error);
    });
  }

  ngOnInit() {
  }

  filters = {};

  _applyFilter(event) {
    this.filters = {...event};
    this.getProducts();
  }
}

import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {CommonService} from "../shared/common/common-services/common-service";

// declare const Parse: any;


@Injectable()
export class RentalService {
  constructor(private http: HttpClient, private cm: CommonService) {
    // this.cm.ParseJs.initialize(environment.PARSE_APP_ID);//, environment.PARSE_JS_KEY
    // this.cm.ParseJs.serverURL = environment.serverURL;
  }

  getParse(objectName, pointers) {
    const Product = this.cm.ParseJs.Object.extend(objectName);
    const query = new this.cm.ParseJs.Query(Product);
    query.descending('createdAt');
    query.include(['pickedFrom.address']);
    // query.include(['user.address']);
    pointers.map(pointer => {
      if (pointer['relationName'] !== 'user')
        query.include(pointer['relationName']);
    });
    query.include('_User');
    return query.find();
  }

  getParseData(results, pointers) {
    const arr = [];
    let temp: any = {};
    results.map((result, index) => {
      temp = {...temp, ...this.formatResult(result, 'rentalId')};
      arr.push(temp);
      pointers.map(pointer => {
        if (pointer['relationName'] === 'address' && result.attributes['pickedFrom']['attributes'][pointer['relationName']]) {
          const object = {};
          object[pointer['relationName']] = this.formatResult(result.attributes['pickedFrom']['attributes'][pointer['relationName']], pointer['relationName'] + 'id');
          arr[index] = {...arr[index], ...object};
          arr[index] = {...arr[index], ...object[pointer['relationName']]};
        } else if (pointer['relationName'] === 'user') {
          let object = {};
          object = this.formatUser(result.attributes[pointer['relationName']], pointer['relationName'] + 'id');
          arr[index] = {...arr[index], ...object};
          // arr[index] = {...arr[index], ...object[pointer['relationName']]};
        } else if (result.attributes[pointer['relationName']]) {
          const object = {};
          object[pointer['relationName']] = this.formatResult(result.attributes[pointer['relationName']], pointer['relationName'] + 'id');
          arr[index] = {...arr[index], ...object};
          arr[index] = {...arr[index], ...object[pointer['relationName']]};
          // temp = {...temp, ...this.formatResult(result.attributes[pointer['relationName']])};
        }
      });
    });
    return arr;
  }

  formatResult(result, id?) {
    let temp: any = {};
    temp = {...result.attributes};
    if (id)
      temp[id] = result.id;
    return temp;
  }

  formatUser(result, id?) {
    let temp: any = {};
    if (id && result)
      temp[id] = result.id;
    return temp;
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {EjectBatteryComponent} from "./eject-battery.component";


const routes: Routes = [{
  path: 'dashboard',
  children: [
    {
    path: 'eject-battery',
    component: EjectBatteryComponent,
  }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class EjectBatteryRoutingModule { }

import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {CommonService} from '../shared/common/common-services/common-service';
import {OverlayService} from '../shared/common/overlay/overlay.service';
import {AddNetworkConfigService} from "./add-network-config.service";

@Component({
  selector: 'app-add-network-config',
  templateUrl: './add-network-config.component.html',
  styleUrls: ['./add-network-config.component.scss']
})
export class AddNetworkConfigComponent implements OnInit {

  constructor(private cm: CommonService, private addNetworkConfigService: AddNetworkConfigService, private vc: ViewContainerRef,
              private overlayService: OverlayService) {
    this.tablesData = this.cm.getNetworkConfigAttributes();
    this.getProducts();
  }

  tablesData: any = {};
  objectName = 'NetworkConfig';
  formData = [ // deviceName, boxCode, bcode and deviceNickName
    {
      key: 'wname',
      placeholder: 'Wifi Name',
      value: '',
      label: '', // no use
      type: 'text',
    },
    {
      key: 'wpwd ',
      placeholder: 'Wifi Passoword',
      value: '',
      label: '', // no use
      type: 'text',
    },
    {
      key: 'connType',
      placeholder: 'Connection Type',
      value: '',
      label: '',
      type: 'select',
      isOptionsPromise: false,
      valueType: 'simple',
      selected: true,
      options: [
        {
          id: '4g',
          name: '4G'
        },
        {
          id: 'wifi',
          name: 'WIFI'
        },
      ],
      viewValue: 'name',
      viewKey: 'id'
    }
  ];

  pageSize = 50;
  pageNumber = 1;
  count = 0;
  loading = true;

  filters = {};
  pointers = [];

  getProducts() {
    this.loading = true;
    this.cm.getParse(this.objectName, this.pointers, this.pageNumber, this.pageSize, this.filters).then(result => {
      // results contains a weight / rank in result.get('score')
      this.count = result.count;
      this.tablesData.data = this.addNetworkConfigService.getParseData(result.results, this.pointers);
      debugger;
      this.tablesData = {...this.tablesData};
      this.loading = false;
    })
      .catch((error) => {
        this.cm.showNotification('danger', error);
      });
  }

  _pageChange(page) {
    this.pageSize = page.pageSize;
    this.pageNumber = page.pageNumber;
    this.getProducts();
  }

  _addNew(formValues) {
    this.addNetworkConfigService.postParseData(formValues, this.formData, this.objectName).then(success => {
      this.cm.showNotification('success', success);
      this.overlayService.closeOverlay();
      this.getProducts();
    }, (error) => {
      this.cm.showNotification('danger', error);
    });
    // this.cm.cloudFunction('addChargingBox', formValues).then(success => {
    //   this.overlayService.closeOverlay();
    //   this.cm.showNotification('success', success);
    //   this.getProducts(true);
    // }, error => {
    //   this.cm.showNotification('danger', error);
    // });
  }

  _update(object) {
    // debugger
    // object.formValues['connType'] = object.formValues['connType']['id'];
    this.cm.getParseDataById(this.objectName, object.primaryKey).then((product) => {
      this.formData.map(formObj => {
        if (object.formValues[formObj['key']]) { // pointer object optional
          product.set(formObj['key'], object.formValues[formObj['key']]);
        }
      });
      product.save().then(updated => {
        this.getProducts();
        this.cm.showNotification('success', updated);
        this.overlayService.closeOverlay();
      }, (error2) => {
        this.cm.showNotification('danger', error2);
      });
    }, (error1) => {
      this.cm.showNotification('danger', error1);
    });
  }

  _delete(primaryKey) {
    // debugger
    this.cm.getParseDataById(this.objectName, primaryKey).then((object) => {
      object.destroy().then((success) => {
        this.cm.showNotification('success', success);
        this.getProducts();
      }, (error) => {
        // The delete failed.
        this.cm.showNotification('danger', error);
        // error is a Parse.Error with an error code and message.
      });
    });
  }

  ngOnInit() {
  }

  _applyFilter(event) {
    this.filters = {...event};
    this.getProducts();
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SharedModule} from '../shared/shared.module';
import {InputControlModule} from "../shared/input-control/input-control-module";
import {CommonsModule} from "../shared/common/common-module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppMaterial} from "../shared/app-material";
import {AddVenueComponent} from "./add-venue.component";
import {AddVenueService} from "./add-venue.service";

@NgModule({
  imports: [
    CommonModule,
    CommonsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterial,
    InputControlModule
  ],
  declarations: [
    AddVenueComponent
  ],
  providers: [
    AddVenueService
  ],
  exports: [
    AddVenueComponent
  ]
})
export class AddVenueModule {
}

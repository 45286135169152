import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewContainerRef} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {CommonService} from "../shared/common/common-services/common-service";
import {ListCustomerService} from "../list-customer/list-customer.service";
import {timeout} from "rxjs/internal/operators";

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit, AfterViewInit {
  userForm = [
    {
      key: 'email',
      placeholder: 'Email',
      value: '',
      label: 'Email:',
      type: 'text',
      disabled: true
    },
    {
      key: 'username',
      placeholder: 'Username',
      value: '',
      label: 'Username:',
      type: 'text',
      disabled: true
    }
  ];
  addressForm = [
    {
      key: 'addressLine1',
      placeholder: 'addressLine1',
      value: '',
      label: '',
      type: 'text',
      disabled: true
    },
    {
      key: 'addressLine2',
      placeholder: 'addressLine1',
      value: '',
      label: '',
      type: 'text',
      disabled: true
    },
    {
      key: 'city',
      placeholder: 'city',
      value: '',
      label: '',
      type: 'text',
      disabled: true
    },
    {
      key: 'state',
      placeholder: 'state',
      value: '',
      label: '',
      type: 'text',
      disabled: true
    },
    {
      key: 'country',
      placeholder: 'country',
      value: '',
      label: '',
      type: 'text',
      disabled: true
    },
    {
      key: 'zip',
      placeholder: 'zip',
      value: '',
      label: '',
      type: 'text',
      disabled: true
    }
  ];
  pointers = [
    {
      // objectName: 'Battery',
      relationName: 'user',
      objectName: 'User',
      formData: this.userForm,
      // relation: true
    },
    {
      objectName: 'Address',
      relationName: 'Address',
      // objectName: 'ChargingBox',
      formData: this.addressForm
    }
  ];
  formData = [
    {
      key: 'firstName',
      placeholder: 'customer_name_first',
      value: '',
      label: 'customer_name_first:',
      type: 'text',
      disabled: true
    },
    {
      key: 'lastName',
      placeholder: 'customer_name_last',
      value: '',
      label: 'customer_name_last:',
      type: 'text',
      disabled: true
    },
    {
      key: 'customer_number',
      placeholder: 'customer_number',
      value: '',
      label: 'customer_number:',
      type: 'text',
      disabled: true
    },
    {
      key: 'app_download_date',
      placeholder: 'app_download_date',
      value: '',
      label: 'app_download_date:',
      type: 'text',
      disabled: true
    },
    {
      key: 'has_payment_info',
      placeholder: 'has_payment_info',
      value: '',
      label: 'has_payment_info:',
      type: 'toggle',
      disabled: true
    },
    {
      key: 'has_rented',
      placeholder: 'has_rented',
      value: '',
      label: 'has_rented:',
      type: 'toggle',
      disabled: true
    },
    {
      key: 'first_rental_date',
      placeholder: 'first_rental_date',
      value: '',
      label: 'first_rental_date:',
      type: 'text',
      disabled: true
    },
    {
      key: 'last_rental_date',
      placeholder: 'last_rental_date',
      value: '',
      label: 'last_rental_date:',
      type: 'text',
      disabled: true
    },
    {
      key: 'rentals_lifetime',
      placeholder: 'rentals_lifetime',
      value: 0,
      label: 'rentals_lifetime:',
      type: 'number',
      disabled: true
    },
    {
      key: 'rentals_7d',
      placeholder: 'rentals_7d',
      value: 0,
      label: 'rentals_7d:',
      type: 'number',
      disabled: true
    },
    {
      key: 'rentals_30d',
      placeholder: 'rentals_30d',
      value: 0,
      label: 'rentals_30d:',
      type: 'number',
      disabled: true
    },
    {
      key: 'lifetime_spend',
      placeholder: 'lifetime_spend',
      value: 0,
      label: 'lifetime_spend:',
      type: 'number',
      disabled: true
    },
    {
      key: 'push_enabled',
      placeholder: 'push_enabled',
      value: '',
      label: 'push_enabled:',
      type: 'toggle',
      disabled: true
    },
    {
      key: 'is_renting',
      placeholder: 'is_renting',
      value: '',
      label: 'is_renting:',
      type: 'toggle',
      disabled: true
    },

  ];
  form: FormGroup;
  customerDetail: any = {};

  constructor(private _fb: FormBuilder, private commonService: CommonService, private listCustomerService: ListCustomerService, private cd: ChangeDetectorRef) {
    this.form = this._fb.group({});
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.listCustomerService.getCustomerDetail().subscribe(data => {
      this.customerDetail = data;
        this.form.patchValue(this.customerDetail);
        this.cd.detectChanges();
      // }, 1000);
    });
  }

}

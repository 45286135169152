import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SharedModule} from '../shared/shared.module';
import {InputControlModule} from '../shared/input-control/input-control-module';
import {CommonsModule} from '../shared/common/common-module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppMaterial} from '../shared/app-material';
import {ActivityLogComponent} from './activity-log.component';
import {ActivityLogService} from "./activity-log.service";

@NgModule({
  imports: [
    CommonModule,
    CommonsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterial,
    InputControlModule
  ],
  declarations: [
    ActivityLogComponent
  ],
  providers: [
    ActivityLogService
  ],
  exports: [
    ActivityLogComponent
  ]
})
export class ActivityLogModule {
}

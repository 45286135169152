import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {CommonService} from "../shared/common/common-services/common-service";

// declare const Parse: any;


@Injectable()
export class AddVenueService {
  constructor(private http: HttpClient, private cm: CommonService) {
    // this.cm.ParseJs.initialize(environment.PARSE_APP_ID);//, environment.PARSE_JS_KEY
    // this.cm.ParseJs.serverURL = environment.serverURL;
  }

  getParse(objectName) {
    const Product = this.cm.ParseJs.Object.extend(objectName);
    const query = new this.cm.ParseJs.Query(Product);
    query.descending('createdAt');
    // pointers.map(pointer => {
    //   query.include(pointer['relationName']);
    // });
    return query.find();
  }

  getParseData(results) {
    const arr = [];
    let temp: any = {};
    results.map((result, index) => {
      temp = {...temp, ...this.formatResult(result, true)};
      arr.push(temp);
      // pointers.map(pointer => {
      //   if (result.attributes[pointer['relationName']]) {
      //     const object = {};
      //     object[pointer['relationName']] = this.formatResult(result.attributes[pointer['relationName']]);
      //     arr[index] = {...arr[index], ...object};
      //     arr[index] = {...arr[index], ...object[pointer['relationName']]};
      //     // temp = {...temp, ...this.formatResult(result.attributes[pointer['relationName']])};
      //   }
      // });
    });
    return arr;
  }

  formatResult(result, id?) {
    let temp: any = {};
    temp = {...result.attributes};
    if (temp['location']) {
      temp['lat'] = temp['location']['_latitude'];
      temp['lng'] = temp['location']['_longitude'];
      temp['location'] = '(' + temp['location']['_latitude'] + ', ' + temp['location']['_longitude'] + ')';
    }
    if (id)
      temp.id = result.id;
    return temp;
  }

  postParseData(data, parentForm, objectName) {
    const _ObjectParent = this.cm.ParseJs.Object.extend(objectName);
    const _objectParent = new _ObjectParent();
    const keys = Object.keys(data);
    parentForm.map(formObj => {
      _objectParent.set(formObj['key'], data[formObj['key']]);
    });
    return _objectParent.save();
  }

  getGeoPoint(lat, lng) {
    return new this.cm.ParseJs.GeoPoint(lat, lng);
  }

  getAddress(lat, lng) {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyCgzZLKci027eBRixTxgy2Vb571nRJ4Vfw');
  }
}

import {
  AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild,
  ViewContainerRef
} from '@angular/core';
import {CommonService} from "../shared/common/common-services/common-service";
import {environment} from "../../environments/environment";
import {OverlayService} from "../shared/common/overlay/overlay.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'app-crud-operations',
  templateUrl: './crud-operations.component.html',
  styleUrls: ['./crud-operations.component.scss']
})
export class CrudOperationsComponent implements OnInit, AfterViewInit {
  @Input() formData = [];
  @Input() count = 0;
  @Input() loading = false;
  @Input() pointers = [];
  @Output() edit = new EventEmitter();
  @Output() applyFilter = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() addNew = new EventEmitter();
  @Output() pageChange = new EventEmitter();
  @Output() detail = new EventEmitter();
  @Output() scanSuccess = new EventEmitter();
  productKey = 0;
  product: any = {};

  @Input()
  set tablesData(product) {
    this.product = {...product};
  }

  get tablesData(): {} {
    return this.product;
  }

  @ViewChild('crud', {static: false}) crud;
  @ViewChild('crudScan', {static: false}) crudScan;
  update = false;
  form: FormGroup;
  detailPointerForm: FormGroup;

  constructor(private _fb: FormBuilder, private cm: CommonService, private overlaySvc: OverlayService,
              private router: Router, private vc: ViewContainerRef) {
    this.form = this._fb.group({});
    this.detailPointerForm = this._fb.group({});
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  _canceled() {
    this.form.reset();
  }

  save() {
    if (this.update === false) { // add
      this.cm.validateAllFormFields(this.form);
      if (this.form.valid) {
        this.addNew.emit(this.form.value);
      }
    } else { // update
      if (this.form.valid) {
        this.edit.emit({formValues: this.form.value, primaryKey: this.productKey});
      }
    }
  }

  _edit(row) {
    this.update = true;
    this.productKey = row['id'];
    this.openCrud(row, this.crud);
    setTimeout(() => {
      debugger
      this.form.patchValue(row);
    }, 1000);
  }

  _addNew() {
    this.update = false;
    this.form.reset();
    this.openCrud(null, this.crud);
  }

  _delete(row) {
    this.delete.emit(row['id']);
  }

  _applyFilter(event) {
    this.applyFilter.emit(event);
  }

  _pageChange(event) {
    this.pageChange.emit(event);
  }

  _detail(row) {
    this.detail.emit(row);
  }

  detailPointer = {}
  showPointerDetail = false;

  _pointerDetailPage(event) {
    this.detailPointer = this.pointers.find(obj => obj.relationName === event['detailKey']);
    this.showPointerDetail = true;
    setTimeout(() => {
      if (event.detailPage)
        this.detailPointerForm.patchValue(event['row']);
      else this.detailPointerForm.patchValue(event['row'][event['detailKey']]);
      this.detailPointerForm.disable();
    }, 1000);
  }

  _scan() {
    this.openCrud(null, this.crudScan);
  }

  _scanSuccess(code) {
    // code = 'getchargefuze.com/fuzebox?box=4567'
    const pair = code.split('=');
    const bcode = pair[1];
    this.scanSuccess.emit(bcode);
    // const ch = this.tablesData['data'].find(obj => obj['bcode'] === bcode);
    // if (ch) {
    //   this.overlaySvc.closeAllOverlays();
    //   setTimeout(() => {
    //     this.router.navigate(['/chargingbox-detail'], {queryParams: {id: ch['id']}});
    //   }, 1000);
    // } else {
    //   this.cm.showNotification('danger', 'Error finding charging box with bcode ' + bcode + '. Either Charging box does not exist or some network issue.');
    // }
  }

  openCrud(data = null, component): void {
    this.overlaySvc.open({
      component: component,
      data,
      viewContainerRef: this.vc,
      backdropClass: 'dark-backdrop',
      panelClass: 'dialog-panel',
      width: '52.61%',
      height: '88%',
    });
  }
}

import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    // { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    // { path: '/user-profile', title: 'User Profile',  icon:'person', class: '' },
  { path: '/test-charging-box', title: 'Test Charging Box',  icon:'person', class: '' },
  { path: '/list-venue', title: 'Station Dashboard',  icon:'person', class: '' },
  { path: '/network', title: 'Network',  icon:'person', class: '' },
    // { path: '/deep-link', title: 'Add Venue',  icon:'person', class: '' },
    // { path: '/customer-detail', title: 'Customer Detail',  icon:'person', class: '' },
    { path: '/list-customer', title: 'List Customer',  icon:'person', class: '' },
    { path: '/earnings', title: 'Earnings',  icon:'person', class: '' },
    { path: '/status-of-chargers', title: 'Status of Chargers',  icon:'person', class: '' },
    // { path: '/product', title: 'Product',  icon:'person', class: '' },
    { path: '/charging-box', title: 'Charging Box',  icon:'person', class: '' },
    { path: '/type', title: 'Charging Box Type',  icon:'person', class: '' },
  { path: '/gc', title: 'Global Config',  icon:'person', class: '' },
// { path: '/eject-battery', title: 'Eject Battery',  icon:'person', class: '' },
    { path: '/rental', title: 'Rental Dashboard',  icon:'person', class: '' },
    { path: '/apk', title: 'Apk Dashboard',  icon:'person', class: '' },
    { path: '/activity', title: 'Activity Dashboard',  icon:'person', class: '' },
    { path: '/promo-codes', title: 'Promo Codes',  icon:'person', class: '' },
    { path: '/promo-codes/reward', title: 'Reward',  icon:'person', class: '' },
    // { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
    // { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
    // { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
    // { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
    // { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
    // { path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}

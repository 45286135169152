import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SharedModule} from '../shared/shared.module';
import {InputControlModule} from "../shared/input-control/input-control-module";
import {CommonsModule} from "../shared/common/common-module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppMaterial} from "../shared/app-material";
import {AddApkService} from "../add-apk/add-apk.service";
import {ChargingBoxTypeComponent} from "./charging-box-type.component";

@NgModule({
  imports: [
    CommonModule,
    CommonsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterial,
    InputControlModule
  ],
  declarations: [
    ChargingBoxTypeComponent
  ],
  providers: [
    AddApkService
  ],
  exports: [
    ChargingBoxTypeComponent
  ]
})
export class ChargingBoxTypeModule {
}
